import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { settings } from "../../settings";
import { Icon } from "@iconify/react/dist/iconify.js";
import { forceToIcon } from "../Barracks/BarracksPreview";
import { PrimaryButton } from "../../app/kit/buttons";
import { goTo, routeNames } from "../../app/slices/navigationSlice";

export const BattleViewPage = () => {
    const battleId = useSelector(x => x.navigation.currentPage.args.battleId);
    const [battle, setBattle] = useState(null);
    const getBattleView = () => {
        axios.get(settings.backendUrl + "/battle/view?slot=" + battleId).then(res => {
            setBattle(res.data);
        });
    }

    useEffect(() => {
        getBattleView();
    }, [])

    const dispatch = useDispatch();
    const onAccept = () => {
        axios.post(settings.backendUrl + "/battle/accept?slot=" + battleId).then(res => {
            dispatch(goTo({ route: routeNames.battleDefenceFill, args: { battle: battle } }))
        });
    }

    return (
        <div className="w-full h-full flex flex-col pb-[6rem]">
            <div className={`w-full h-full flex flex-col text-black/70 justify-center`}>
                <div className="flex flex-col gap-1">
                    {battle?.attackerArmies.toReversed().map((x, i) =>
                        <div className="w-full inline-flex justify-center">
                            <div className={`inline-flex gap-1 bg-no-repeat py-2 px-2 `}
                                style={{ backgroundImage: "url(papiero1.png)", backgroundSize: "100% 100%" }}>
                                <p>{x.order}.</p>
                                <Icon icon={forceToIcon(x.forceType).text} className="h-5 w-5" />
                                <p>{x.amount}</p>
                            </div>
                        </div>)}
                </div>
                <div className="w-full inline-flex pb-5 justify-center text-white">
                    <p>-------{battle?.countryName}-------</p>
                </div>

            </div>
            <PrimaryButton text="ACCEPT" onClick={onAccept} />
        </div>
    )
}