import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { settings } from "../../settings"
import { forceMap, forceToIcon } from "../Barracks/BarracksPreview";
import { Icon } from "@iconify/react/dist/iconify.js";
import { PrimaryButton } from "../../app/kit/buttons";
import { useSelector } from "react-redux";



export const BattleReplayPage = () => {

    const replayId = useSelector(x => x.navigation.currentPage.args.replayId);
    const innerReplay = useSelector(x=>x.navigation.currentPage.args.replay);
    const [replay, setReplay] = useState(innerReplay ?? null);

    const [replayInner, setReplayInner] = useState(null);
    const [replaying, setReplaying] = useState(false);
    const loadReplay = () => {
        axios.get(settings.backendUrl + "/battle/replay?slot="+replayId).then(res => {
            setReplay(res.data);
        });
    }

    const replayNext = () => {
        console.log("Updating fight", replayInner);
        if (replayInner.entries.length === 0) {
            setReplaying(false);
            clearInterval(interval.current);
            return;
        }

        setReplayInner(x => {
            let clone = JSON.parse(JSON.stringify(x));
            let nextStep = clone.entries[0];
            
            if (!clone.prepared) {
                let current = 0;
                console.log("Preparing state");
                while(nextStep.isArcherEntry){
                    console.log("Iterating over archers", current, clone);
                    if (nextStep.isAttacker) {
                        clone.defenderSlots[0].toBeKilled = clone.defenderSlots[0].toBeKilled===undefined? nextStep.targetKilled:
                        clone.defenderSlots[0].toBeKilled+nextStep.targetKilled;
                    }
                    else {
                        clone.attackerSlots[0].toBeKilled = clone.attackerSlots[0].toBeKilled===undefined? nextStep.targetKilled:
                        clone.attackerSlots[0].toBeKilled+nextStep.targetKilled;
                    }
                    clone.entries[0] = nextStep;
                    current +=1;
                    
                    console.log("Next step after archers",nextStep);
                    clone.accumulated = clone.accumulated === undefined?1:clone.accumulated+1;
                    if (current<clone.entries.length-1){
                        nextStep = clone.entries[current];
                    }
                    else{
                        break;
                    }
                }    
                if (clone.accumulated){
                    clone.prepared = true;
                    return clone;
                }
                
                if (nextStep.isAttacker) {
                    clone.defenderSlots[0].toBeKilled = nextStep.targetKilled;
                    clone.attackerSlots[0].toBeKilled = nextStep.sourceKilled;
                }
                else {
                    clone.attackerSlots[0].toBeKilled = nextStep.targetKilled;
                    clone.defenderSlots[0].toBeKilled = nextStep.sourceKilled;
                }
            } else {
                //update state
                console.log("Updating prepared state");
                if (clone.defenderSlots[0].toBeKilled !== undefined) {
                    console.log("Clear defender");
                    clone.defenderSlots[0].currentAmount -= clone.defenderSlots[0].toBeKilled;
                    clone.defenderSlots[0].toBeKilled = undefined;
                    if (clone.defenderSlots[0].currentAmount <= 0) {
                        console.log("Defender army killed", nextStep.targetKilled);
                        clone.defenderSlots.shift();
                    }
                }
                if (clone.attackerSlots[0].toBeKilled !== undefined) {
                    console.log("Clear attacker");
                    clone.attackerSlots[0].currentAmount -= clone.attackerSlots[0].toBeKilled;
                    clone.attackerSlots[0].toBeKilled = undefined;
                    if (clone.attackerSlots[0].currentAmount <= 0) {
                        console.log("Attacker army killed", nextStep.targetKilled);
                        clone.attackerSlots.shift();
                    }
                }
            }


            if (clone.prepared) {
                console.log("Shifting after prepare,", clone.accumulated, clone.entries);
                clone.entries = clone.entries.slice(clone.accumulated === undefined?1:clone.accumulated);
                console.log("After shift", clone.entries);
                clone.accumulated = undefined;
                clone.prepared = undefined;
            }
            else {
                clone.prepared = true;
                clone.entries[0] = nextStep;
            }

            return clone;
        })
    }

    const interval = useRef(null);

    useEffect(() => {
        if (replayInner !== null && !replaying){
            if (interval.current) {
                clearInterval(interval.current);
            }
            /*
            interval.current = setInterval(() => {
                //replayc
                console.log("Replay iteration", replayInner);
                if (replayInner!==null){
                    replayNext();
                };
            }, 2000);*/
        }
    },[replayInner])

    useEffect(() => {

        if (replay !== null && !replaying) {
            //prepare new entity to track progress
            setReplayInner(replay);
        }
    }, [replay])

    useEffect(() => {
        if (replay === null){
            loadReplay();
        }        
        return () => {
            setReplaying(false);
            clearInterval(interval.current);
        }
    }, [])
  
    const isArcheryRound = replayInner?.entries[0]?.isArcherEntry;
    const prepared = replayInner?.prepared;
    console.log("Round archery:", isArcheryRound, prepared);
    return (
        <div className="w-full h-full flex flex-col pb-[6rem]">
            {replay !== null && 
            <div className="inline-flex">
                <p>{replay.isWin ? "You Won!":"You Lost!"}</p>
                <p>{`(Tokens stealed: ${replay.stealed})`}</p>
            </div>}
            {replayInner && <div className={`w-full h-full flex flex-col text-black/70 justify-center`}>
                <>
                    <div className="flex flex-col gap-1">
                        {replayInner.attackerSlots.toReversed().map((x, i) =>
                            <div className="w-full inline-flex justify-center" style={{ paddingTop: i === replayInner.attackerSlots.length - 1 && (replayInner?.prepared && !replayInner?.entries[0].isArcherEntry) ? "2rem" : "0rem" }}>
                                <div className={`inline-flex gap-1 bg-no-repeat py-2 px-2 `} 
                                style={{ backgroundImage: "url(papiero1.png)", backgroundSize: "100% 100%" }}>
                                    <Icon icon={forceToIcon(x.forceType).text} className={`h-5 w-5 ${prepared&&isArcheryRound && x.forceType === forceMap.Archer?"text-green-600":""}`} />
                                    <p>{x.currentAmount}</p>
                                    {x.toBeKilled !== undefined && <p className="text-red-600">-{x.toBeKilled}</p>}
                                </div>
                            </div>)}
                    </div>
                    {(!replayInner?.prepared || replayInner?.entries[0].isArcherEntry) && <div className="h-12" />}
                    <div className="flex flex-col gap-1">
                        {replayInner.defenderSlots.map((x, i) =>
                            <div className="w-full inline-flex justify-center" style={{ paddingBottom: i === 0 && (replayInner?.prepared && !replayInner?.entries[0].isArcherEntry) ? "2rem" : "0rem" }}>
                                <div className="inline-flex gap-1 bg-no-repeat py-2 px-2" style={{ backgroundImage: "url(papiero1.png)", backgroundSize: "100% 100%" }}>
                                    <Icon icon={forceToIcon(x.forceType).text} className={`h-5 w-5 ${prepared&&isArcheryRound && x.forceType === forceMap.Archer?"text-green-600":""}`}  />
                                    <p>{x.currentAmount}</p>
                                    {x.toBeKilled !== undefined && <p className="text-red-600">-{x.toBeKilled}</p>}
                                </div>
                            </div>)}
                    </div>
                </>
            </div>}
            {replayInner?.entries?.length > 0 && <PrimaryButton text="Next" onClick={replayNext} />}
            {replayInner?.entries?.length === 0 && <PrimaryButton text="Start over" onClick={()=>setReplayInner(replay)} />}
        </div>
    )
}