import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    modal:null
}

export const modalSlice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        setModal(state,action){
            state.modal = action.payload;
        },
        closeModal(state, action){
            state.modal = null;
        },
    },   
});

export const {
    setModal,
    closeModal
} = modalSlice.actions

export default modalSlice.reducer