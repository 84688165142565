import axios from "axios"
import { settings } from "../../settings"
import { useEffect, useState } from "react";
import { secondsToHumanReadable } from "../Balances/BalanceView";
import { useDispatch, useSelector } from "react-redux";
import { classNames, PrimaryRightButton } from "../../app/kit/buttons";
import { goTo, routeNames } from "../../app/slices/navigationSlice";
import { Icon } from "@iconify/react/dist/iconify.js";
import { formNumber } from "../../app/helpers/numberFormatter";


const BattleSlotPlank = ({ battleSlot }) => {

    const dispatch = useDispatch();
    return (
        <div className="w-max inline-flex items-center px-2 py-1 bg-no-repeat" style={{ backgroundImage: "url(papiero1.png)", backgroundSize: "100% 100%" }}>
            {battleSlot.isAttack ?
            <Icon icon="emojione:crossed-swords" className={classNames("h-5 w-5", battleSlot.isWin ? "text-green-600":"text-red-600")} />:
            <Icon icon="dashicons:shield" className={classNames("h-5 w-5", battleSlot.isWin ? "text-green-600":"text-red-600")} />}
            <p>{battleSlot.countryName}</p>
            <p className="ml-1">{`(${battleSlot.isWin?"+":"-"}${formNumber(battleSlot.tokensStealed)})`}</p>
            <PrimaryRightButton size="small" text={"VIEW"} onClick={()=>dispatch(goTo({ route: routeNames.battleReplay, args: {replayId:battleSlot.battleId} }))}/>
        </div>
    )
}

const BattleHistoryPage = () => {
    const [battleList, setBattleList] = useState([]);
    const dispatch = useDispatch();
    const requestList = () => {
        axios.get(settings.backendUrl + "/battle/history").then(res => {
            setBattleList(res.data);       
        });
    }

    useEffect(() => {
        requestList();
    }, [])



    return (
        <div className="w-full h-full flex flex-col gap-2 pt-2 text-black/70">
            {battleList.map(x => (
                <div key={x.battleSlotId}  className="w-full inline-flex justify-center">
                    <BattleSlotPlank battleSlot={x} />
                </div>
            ))}
        </div>
    )
}

export default BattleHistoryPage;