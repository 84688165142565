import { useDispatch, useSelector } from "react-redux";
import { StatContainer } from "../../app/kit/containers";
import { resourcesMap, resourceToIcon } from "../Stock/StockPreview";
import { classNames, PrimaryButton } from "../../app/kit/buttons";
import { ComboResourceInput } from "../../app/kit/comboInput";
import { useEffect, useMemo, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { ExchangeIcon } from "../../icons/ExchangeIcon";
import axios from "axios";
import { settings } from "../../settings";
import { dealComplete } from "../../app/slices/dataSlice";
import { formNumber } from "../../app/helpers/numberFormatter";
import { tryVibrate } from "../../app/helpers/vibrateHelper";

export const MarketResourceList = () => {
    const resources = useSelector((state) => state.data.runtime.stock.resources);
    return (
        <div className="flex flex-row gap-2 w-max p-2">
            {resources.map(x => <StatContainer text={formNumber(x.amount)} icon={resourceToIcon(x.resourceType)} />)}
        </div>
    )
}

const MarketExchangeInputForm = ({ resource, zIndex, options, setMax }) => {

    return (
        <div className="w-full relative" style={{ zIndex: zIndex }}>
            <ComboResourceInput maxAmountClick={setMax} resource={resource.resourceType} setResource={resource.setResourceType} options={options} amount={resource.amount} setAmount={resource.setAmount} />
        </div>
    )
}

const MarketExchangeInputBlock = ({ resources, userResourceTypes, setMax, rate }) => {
    console.log(resources);
    return (
        <div className="w-full inline-flex gap-4 relative">
            <div className="flex flex-col gap-1 bg-mainBg  rounded-lg px-2 pb-2">
                <div className="w-full inline-flex justify-center items-center pt-2">
                    <ExchangeIcon className="h-12 w-12" />
                </div>
                <div className="inline-flex justify-center items-center gap-1">
                    <div className="flex flex-row items-center gap-1">
                        <span className=" text-lg">{formNumber(1 / rate)}</span>
                        <Icon icon={resourceToIcon(resources.resource1.resourceType).icon} className={resourceToIcon(resources.resource1.resourceType).className} />
                    </div>
                    <p>/</p>
                    <div className="flex flex-row items-center gap-1">
                        <span className="  text-lg">1</span>
                        <Icon icon={resourceToIcon(resources.resource2.resourceType).icon} className={resourceToIcon(resources.resource2.resourceType).className} />
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
                <MarketExchangeInputForm resource={resources.resource1} zIndex={30} options={userResourceTypes} setMax={setMax.set1} />
                <MarketExchangeInputForm resource={resources.resource2} zIndex={20} options={Object.keys(resourcesMap)} setMax={setMax.set2} />
            </div>

        </div>
    )
}



const MarketExchangeBlock = () => {

    const stockLoaded = useSelector((state) => state.data.isLoaded);
    const userResources = useSelector((state) => state.data.runtime.stock.resources);
    const userResourceTypes = useMemo(() => userResources.map(x => x.resourceType), [userResources]);




    const [exchangeState, setExchangeState] = useState({
        resource1: userResourceTypes[0],
        resource2: Object.keys(resourcesMap).filter(x => x !== userResourceTypes[0])[0],
        amount1: 0,
        amount2: 0,
        rate: 5
    });

    const [isLoading, setIsLoading] = useState(false);

    const updateRate = (res1, res2) => {
        setIsLoading(true);
        axios.get(settings.backendUrl + "/rate?resourceType1=" + res1 + "&resourceType2=" + res2).then((response) => {
            setExchangeState(x => {
                var rate = response.data.value;

                let currentAmount = userResources.find(resource => resource.resourceType === res1)?.amount;
                const newRes1Amount = x.amount1 > currentAmount ? currentAmount : x.amount1;
                const newRes2Amount = (newRes1Amount * rate);

                return {
                    ...x,
                    resource1: res1,
                    resource2: res2,
                    amount1: newRes1Amount,
                    amount2: newRes2Amount,
                    rate: rate
                };
            });
        }).finally(() => setIsLoading(false))
    }

    const updateAmount1 = (newAmount) => {

        setExchangeState(x => {
            let currentAmount = userResources.find(resource => resource.resourceType === x.resource1)?.amount;

            var calculatedAmount = newAmount > currentAmount ? currentAmount : newAmount;
            return {
                ...x,
                amount1: calculatedAmount,
                amount2: (calculatedAmount * x.rate)
            }
        });
    }

    const updateAmount2 = (newAmount) => {

        setExchangeState(x => {

            let currentAmount = userResources.find(resource => resource.resourceType === x.resource1)?.amount;
            var newRes1Amount = (newAmount / x.rate);
            if (newRes1Amount > currentAmount) {
                return {
                    ...x,
                    amount2: currentAmount * x.rate,
                    amount1: currentAmount,
                }
            }

            return {
                ...x,
                amount2: newAmount,
                amount1: newRes1Amount
            }
        })
    }

    const selectResource1 = (res1) => {
        if (res1 === exchangeState.resource1) return;
        const targetRes1 = res1;
        const targetRes2 = exchangeState.resource2 === res1 ? exchangeState.resource1 : exchangeState.resource2;

        updateRate(targetRes1, targetRes2);
    }

    const selectResource2 = (res2) => {
        if (res2 === exchangeState.resource2) return;

        const targetRes2 = res2;

        let targetRes1 = res2 === exchangeState.resource1 ? exchangeState.resource2 : exchangeState.resource1;

        const available = userResourceTypes.filter(x => x !== res2);
        if (available.length === 0) return;

        if (!available.includes(targetRes1)) {
            targetRes1 = available[0];
        }
        updateRate(targetRes1, targetRes2);
    }

    const setMax1 = () => {
        let currentAmount = userResources.find(resource => resource.resourceType === exchangeState.resource1)?.amount;
        updateAmount1(currentAmount);
    }

    const setMax2 = () => {
        let currentAmount = userResources.find(resource => resource.resourceType === exchangeState.resource1)?.amount;
        updateAmount2(currentAmount / exchangeState.rate);
    }


    useEffect(() => {
        updateRate(exchangeState.resource1, exchangeState.resource2);
    }, [])


    const dispatch = useDispatch();
    const onDeal = () => {
        axios.post(settings.backendUrl + "/deal", {
            rate: exchangeState.rate,
            sourceResourceType: exchangeState.resource1,
            sourceResourceAmount: exchangeState.amount1,
            targetResourceType: exchangeState.resource2,
        }).then((res) => {
            if (res.data.isSuccess) {
                dispatch(dealComplete(res.data));
                setExchangeState(x => ({ ...x, amount1: 0, amount2: 0 }));
            }
            else {
                //TODO
            }
        });
    }

    return (
        <div className="flex flex-col px-5">
            {stockLoaded ? <MarketExchangeInputBlock rate={exchangeState.rate} resources={{
                resource1: { resourceType: exchangeState.resource1, amount: exchangeState.amount1, setResourceType: selectResource1, setAmount: updateAmount1 },
                resource2: { resourceType: exchangeState.resource2, amount: exchangeState.amount2, setResourceType: selectResource2, setAmount: updateAmount2 }
            }}
                setMax={{ set1: setMax1, set2: setMax2 }}
                userResourceTypes={userResourceTypes} /> : <p>Loading...</p>}

            <div className="inline-flex w-full justify-center pt-5">
                <PrimaryButton
                 className="!text-xl !py-3 !px-4 font-bold"
                    disabled={isLoading || exchangeState.amount1 === 0 || exchangeState.amount2 === 0}
                    onClick={() => { tryVibrate(); onDeal(); }}
                    text="DEAL" />
            </div>

        </div>
    )
}

const MarketTop = () => {
    return (
        <div className="w-full relative pt-6 pb-6">
            <p className="text-5xl w-full text-center">MARKET</p>
        </div>
    )
}

const MarketHeader = () => {
    return (
        <div className="flex flex-col w-full h-max relative bg-cover bg-no-repeat bg-center pb-5" style={{ backgroundImage: 'url(Market.png)' }}>
            <MarketTop />
            <MarketExchangeBlock />
        </div>

    )
}

export default MarketHeader;