import { createSlice } from '@reduxjs/toolkit'
import { requestNewToken } from '../authThunk';

const initialState = {
    loggedIn: false,
    name: '',
    addr: '',
    error: false,
    token: "",
    initialized: false,
    country: null,
    refreshingToken: false
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        rejectUser(state, action) {
            state.error = true;
            state.loggedIn = false;
            state.name = "";
            state.token = "";
            state.addr = '';
            state.initialized = true;
            state.country = null;
        },
        setUser(state, action) {
            state.name = action.payload.name;
            state.error = false;
            state.addr = action.payload.user;
            state.loggedIn = true;
            state.initialized = true;
            state.country = action.payload.country;
        },
        selectCountry(state, action) {
            state.country = action.payload;
        },
        setToken(state, action) {
            state.token = action.payload;
        },
        setRefreshing(state, action) {
            state.refreshingToken = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(requestNewToken.fulfilled, (state, action) => {
            if (action.payload.skip) {
                return;
            } else if (action.payload.status === 200) {
                state.token = action.payload.data;
            } else if (action.payload.status === 401) {
                state.error = true;
                state.loggedIn = false;
                state.name = "";
                state.token = "";
                state.initialized = true;
            }
            state.refreshingToken = false;
        });
    }
});

export const {
    setUser,
    rejectUser,
    setToken,
    setRefreshing,
    selectCountry
} = userSlice.actions

export default userSlice.reducer