import { Icon } from "@iconify/react/dist/iconify.js";
import { useDispatch, useSelector } from "react-redux";
import { recalcStock } from "../../app/slices/dataSlice";
import { useEffect } from "react";
import { PrimaryButton, PrimaryRightButton } from "../../app/kit/buttons";
import { StatContainer } from "../../app/kit/containers";
import { formNumber } from "../../app/helpers/numberFormatter";
import { goTo, routeNames } from "../../app/slices/navigationSlice";
import { AutoTextSize } from "auto-text-size";

export const forceMap = {
    Cavalry: "Cavalry",
    Infantry: "Infantry",
    Archer: "Archer",
    Pikeman: "Pikeman"
}
export const forceToIcon = (forceType) => {
    switch (forceType) {
        case forceMap.Cavalry:
            return { text: "game-icons:mounted-knight", className: "text-gray-900" };
        case forceMap.Infantry:
            return { text: "game-icons:swordman", className: "text-gray-900" };
        case forceMap.Archer:
            return { text: "game-icons:bowman", className: "text-gray-900" };
        case forceMap.Pikeman:
            return { text: "game-icons:hooded-assassin", className: "text-gray-900" };
    }

}

const BarracksResourceBlock = () => {
    const barracks = useSelector((state) => state.data.runtime.barracks);
    return (
        <div className="flex flex-col gap-1 w-max">
            {barracks.forces.map(x => <StatContainer text={formNumber(x.amount)} icon={forceToIcon(x.forceType)} />)}
        </div>
    )
}

const BarracksAmountBlock = () => {
    const { currentAmount, currentCapacity } = useSelector((state) => state.data.runtime.barracks);
    return (
        <StatContainer text={`${formNumber(Math.floor(currentAmount))} / ${formNumber(currentCapacity)}`} textClassName="!text-md" className="w-max text-nowrap px-2" />
    )
}

const BarracksProgressBar = () => {

    const { currentAmount, currentCapacity } = useSelector((state) => state.data.runtime.barracks);
    const load = 100 * currentAmount / currentCapacity;



    return (
        <div className="h-full w-3 overflow-hidden rounded-full bg-backlight/70 flex flex-col justify-end">
            <div className="rounded-full bg-accent" style={{ height: `${load}%` }} />
        </div>
    )
}

const BarracksPreview = () => {
    const level = useSelector((state) => state.data.barracks.level);
    const dispatch = useDispatch();
    return (
        <div className="flex justify-center h-full w-full bg-no-repeat bg-center" style={{ backgroundImage: "url(Barracks.png)", backgroundSize:"120% 120%" }}>
            <div className="flex flex-col w-full pl-1">
                <div className="flex inline-flex w-full items-center h-12 justify-between pr-1">
                    <div className="py-2">
                        <BarracksAmountBlock />
                    </div>
                    <div className="w-full h-full flex items-center justify-end pl-4">
                        <AutoTextSize className="self-center text-right text-white opacity-70 " mode="box">
                            BARRACKS
                        </AutoTextSize>
                    </div>
                </div>
                <div className="flex w-full h-full justify-between">
                    <div className="flex w-full h-full">
                        <BarracksProgressBar />
                        <BarracksResourceBlock />
                    </div>
                    <div className="flex flex-col w-full h-full justify-between">
                        <div className="inline-flex w-full justify-end">
                            <PrimaryRightButton text={"LVL " + level}
                                icon="pepicons-pencil:hammer-claw"
                                onClick={() => dispatch(goTo({ route: routeNames.stockUpdate, args: {} }))} />
                        </div>
                        <div className="inline-flex justify-end pb-2">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BarracksPreview;