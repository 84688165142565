import { useDispatch, useSelector } from "react-redux";
import { NxpsIcon } from "../../icons/Nxps";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useEffect, useState } from "react";
import { formNumber } from "../../app/helpers/numberFormatter";
import { PrimaryButton } from "../../app/kit/buttons";
import { goTo, routeNames } from "../../app/slices/navigationSlice";

const TokenBalanceBlock = ({ amount }) => {
    return (
        <div className="inline-flex gap-2 items-center px-3  rounded-lg">
            <NxpsIcon className="w-10 h-10 stroke-3" />
            <p className="text-md ">{formNumber(amount)}</p>
        </div>
    )
}

export const secondsToHumanReadable = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
        return `${days}d ${hours % 24}h`;
    }
    if (hours > 0) {
        return `${hours}h ${minutes % 60}m`;
    }
    if (minutes > 0) {
        return `${minutes}m ${seconds % 60}s`;
    }
    return `${seconds}s`;
}


const BalanceView = () => {

    const balance = useSelector(state => state.data.balance);
    const dispatch = useDispatch();
    return (
        <div className="w-full inline-flex justify-center py-4 px-4 items-center">
            <TokenBalanceBlock amount={balance.tokenAmount} />            
        </div>
    )
}

export default BalanceView;