import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { settings } from "../../settings";
import { Icon } from "@iconify/react/dist/iconify.js";
import { forceToIcon } from "../Barracks/BarracksPreview";
import { PrimaryButton } from "../../app/kit/buttons";
import { ReactSortable } from "react-sortablejs";
import { SubmitNewForceBlock } from "./BattlePreparePage";
import { goTo, routeNames } from "../../app/slices/navigationSlice";

export const BattleDefenceFillPage = () => {
    const battle = useSelector(x => x.navigation.currentPage.args.battle);
    const [selectedForces, setSelectedForces] = useState([]);
    const userForces = useSelector((state) => state.data.runtime.barracks.forces);
    const userForceTypes = useMemo(() => userForces.map(x => x.forceType), [userForces]);
    const [editSlot, setEditSlot] = useState(-1);
    const onSetAfterSwap = (newState) => {
        setSelectedForces([...newState].map((x, i) => ({ ...x, order: i + 1 })));
    }

    const onSubmitForce = (force) => {
        var index = selectedForces.findIndex(x => x.order === force.order);
        if (index !== -1) {
            setSelectedForces([...selectedForces.slice(0, index), force, ...selectedForces.slice(index + 1)]);
        }
        else {
            setSelectedForces([...selectedForces, force]);
        }
        setEditSlot(-1);
    }
    const dispatch = useDispatch();
    const onDone = () => {
        axios.post(settings.backendUrl + "/battle/run", {
            slotId: battle.battleSlotId,
            armies: selectedForces
        }).then(res => {
            dispatch(goTo({ route: routeNames.battleReplay, args: { replay: res.data } }));
            //TODO: show win/loose and replay screen for battle, res will return ready-to-go replay
        })
    }

    return (
        <div className="w-full h-full flex flex-col pb-[6rem]">
            <div className={`w-full h-full flex flex-col text-black/70 justify-center`}>
                <div className="w-full inline-flex pb-5 justify-center">
                    <div className="flex flex-col">
                        <div>Forest: {battle?.battleConfig["Forest"]}%</div>
                        <div>Mountains: {battle?.battleConfig["Mountains"]}%</div>
                        <div>Hills: {battle?.battleConfig["Hills"]}%</div>
                        <div>Fields: {battle?.battleConfig["Fields"]}%</div>
                    </div>
                </div>
                <div className="flex flex-col gap-1">
                    {battle?.attackerArmies.toReversed().map((x, i) =>
                        <div className="w-full inline-flex justify-center">
                            <div className={`inline-flex gap-1 bg-no-repeat py-2 px-2 `}
                                style={{ backgroundImage: "url(papiero1.png)", backgroundSize: "100% 100%" }}>
                                <p>{x.order}.</p>
                                <Icon icon={forceToIcon(x.forceType).text} className="h-5 w-5" />
                                <p>{x.amount}</p>
                            </div>
                        </div>)}
                </div>
                <div className="w-full inline-flex pb-5 justify-center text-white">
                    <p>-------{battle?.countryName}-------</p>
                </div>
                <div className="w-full h-full flex flex-col">
                    <div className="w-full inline-flex justify-center pb-10">
                        <ReactSortable list={selectedForces} setList={onSetAfterSwap} className="flex flex-col gap-2 text-black/70">
                            {selectedForces.map((force) => (
                                <div key={force.order} className="bg-no-repeat inline-flex px-2 py-1" style={{ backgroundImage: "url(papiero1.png)", backgroundSize: "100% 100%" }}>
                                    <p className="mr-2">{force.order}.</p>
                                    <Icon icon={forceToIcon(force.forceType).text} className="w-6 h-6" />
                                    <p className="">{force.amount}</p>
                                    <div className="w-2 ml-3 mr-2">|||</div>
                                </div>
                            ))}
                        </ReactSortable>
                    </div>
                    <div className="w-full pb-[8rem] flex flex-col">
                        {editSlot >= 0 &&
                            <SubmitNewForceBlock
                                lastIndex={selectedForces.length}
                                armies={selectedForces}
                                forces={userForces}
                                forceTypes={userForceTypes}
                                onCancel={() => { setEditSlot(-1) }}
                                onSubmit={onSubmitForce} />
                        }
                        {editSlot < 0 && <PrimaryButton text="Add" onClick={() => { setEditSlot(0) }} />}
                    </div>
                </div>
            </div>
            {selectedForces.length > 0 && <div className="w-full inline-flex justify-center">
                <PrimaryButton text="BATTLE" onClick={onDone} />
            </div>
            }

        </div>
    )
}