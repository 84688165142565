import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { settings } from "../settings";
import { rejectUser, setUser } from "../app/slices/userSlice";

export const useUser = ()=>{
    const dispatch = useDispatch();
    const userError = useSelector(state => state.user.error);

    const token = useSelector(state => state.user.token);
    
    const updateUserInfo = () => {
        axios.get(settings.backendUrl + "/me")
        .then(res => {
          if (res.status === 200) {
            dispatch(setUser(res.data));
          }
          else {
            dispatch(rejectUser());              
          }
        })
        .catch(e => {
          dispatch(rejectUser());            
        })
        .finally(() => {
        });
      }
    
      useEffect(() => {
        if (token===""){
          dispatch(rejectUser());      
        }
      }, []);

      useEffect(()=>{
        if (token!==""){         
          updateUserInfo();
        }
      },[token])

      return [userError];
}