import axios from "axios";
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { settings } from "../settings";
import { loadUpdate } from "../app/slices/dataSlice";
import MainPage from "./Pages/MainPage";
import MarketPage from "./Pages/MarketPage";
import StockUpdatePage from "./Pages/StockUpdatePage";
import CountryPage from "./Pages/CountryPage";
import { useStockUpdates } from "./Stock/useStockUpdates";
import { routeNames } from "../app/slices/navigationSlice";
import { ModalOutlet } from "./Modals/ModalsCommon";
import LandUpgradePage from "./Pages/LandUpgradePage";
import { useBarracksUpdates } from "./Barracks/useBarracksUpdates";
import BarracksUpdatePage from "./Pages/BarracksUpdatePage";
import BattlePreparePage from "./Pages/BattlePreparePage";
import BattleListPage from "./Pages/BattleList";
import BattleStartPage from "./Pages/BattleStartPage";
import { BattleReplayPage } from "./Pages/BattleReplayPage";
import { BattleViewPage } from "./Pages/BattleViewPage";
import { BattleDefenceFillPage } from "./Pages/BattleDefenceFillPage";
import BattleHistoryPage from "./Pages/BattleHistoryPage";
import TrainingUpgradePage from "./Pages/TrainingUpgradePage";

const routingTable = {
    [routeNames.home]: <MainPage />,
    [routeNames.market]: <MarketPage />,
    [routeNames.stockUpdate]:  <StockUpdatePage  />,
    [routeNames.country]:  <CountryPage  />,
    [routeNames.landUpgrade]:  <LandUpgradePage  />,
    [routeNames.barracksUpgrade]: <BarracksUpdatePage/>,
    [routeNames.battlePrepare]: <BattlePreparePage />,
    [routeNames.battleList]: <BattleListPage />,
    [routeNames.battleSelectCounrty]: <BattleStartPage />,
    [routeNames.battleReplay]: <BattleReplayPage />,
    [routeNames.battleView]: <BattleViewPage/>,
    [routeNames.battleDefenceFill]: <BattleDefenceFillPage/>,
    [routeNames.battleHistory]:<BattleHistoryPage/>,
    [routeNames.trainingUpgrade]: <TrainingUpgradePage />,
}

const MainFrame = () => {
    const loaded = useSelector(x => x.data.isLoaded);
    const dispatch = useDispatch();

    const updateData = () => {
        axios.get(settings.backendUrl + '/update').then(res => {
            dispatch(loadUpdate(res.data));
        }).finally(() => {
        });
    }

    useEffect(() => {
        const interval = setInterval(() => {
            updateData();
        }, 60000); // 1 minute in milliseconds
        updateData();
        return () => clearInterval(interval);
    }, []);

    useStockUpdates();
    useBarracksUpdates();
    const currentPage= useSelector(x=>x.navigation.currentPage);   

    return (
        <>
            {!loaded ? <div>Loading...</div> :
            <ModalOutlet>
                {routingTable[currentPage.route]}
            </ModalOutlet>
            }
        </>
    )
}

export default MainFrame;