import { TonConnectButton } from "@tonconnect/ui-react";
import { useDispatch, useSelector } from "react-redux";
import { classNames, PrimaryButton, PrimaryEmblemRightButton, PrimaryRightButton } from "../../app/kit/buttons";
import { AutoTextSize } from "auto-text-size";
import { goTo, routeNames } from "../../app/slices/navigationSlice";
import { tryVibrate } from "../../app/helpers/vibrateHelper";
import { AttackEmblem, DefenceEmblem } from "../../icons/Emblems";


const CountryProgressBar = ({ countryName }) => {

    const countryPageSelected = useSelector(x => x.navigation.currentPage.route === routeNames.country)
    const dispatch = useDispatch();
    const level = useSelector(x => x.data.countries[countryName].currentLevel);
    const progress = useSelector(x => x.data.countries[countryName].progress);
    return (
        <div className="w-full inline-flex items-center px-3 pb-1">
            <div className="w-max text-nowrap pr-2 text-md font-bold ">
                <span className="font-bold text-md">LVL {level}</span>
            </div>
            <div className="w-full">
                <div aria-hidden="true" className="">
                    <div className="overflow-hidden rounded-full bg-white shadow-sm shadow-black">
                        <div style={{ width: `${progress}%` }} className="h-2 rounded-full bg-accent" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export const CountryNameBlock = ({ countryName, hideOnHeader }) => {

    const visible = useSelector(x => x.navigation.headerVisible);
    return (
        <div className="w-full h-full items-center font-normal inline-flex justify-center px-3 -mb-3">
            <AutoTextSize className={`${hideOnHeader && visible ? "!hidden" : ""}`} mode="boxoneline">
                {countryName.toUpperCase()}</AutoTextSize>
        </div>
    )
}



const TopBlock = ({ hideOnHeader }) => {

    const user = useSelector(x => x.user);

    return (
        <div className="w-full max-h-32 px-5 py-2 pt-4">
            <div className="flex flex-col w-full h-full px-4 py-1 bg-no-repeat text-black/70" style={{ backgroundImage: "url(papiero1.png)", backgroundSize: "100% 100%" }}>
                <CountryNameBlock countryName={user.country} hideOnHeader={hideOnHeader} />
                <CountryProgressBar countryName={user.country} />
            </div>
        </div>
    )
}

const CountryPreview = ({ hideOnHeader, showAttacks = false }) => {

    const user = useSelector(x => x.user);
    const dispatch = useDispatch();
    return (
        <div className={classNames(`w-full h-full relative bg-cover bg-no-repeat woodenborder-bottom`, showAttacks ? "pt-12" : "")} style={{ backgroundImage: `url(${user.country}.png)` }}>
            <div className="w-full h-full flex flex-col">
                <TopBlock hideOnHeader={hideOnHeader} />
                {showAttacks && <div className="w-full h-full inline-flex justify-end">
                    <div className="h-full flex flex-col relative">                    
                        <div className="inline-flex justify-end items-center">
                            <PrimaryEmblemRightButton 
                            text="ATTACK"
                            Emblem={AttackEmblem}                       
                            onClick={() => dispatch(goTo({ route: routeNames.battleSelectCounrty, args: {} }))} />
                        </div>
                        <div className="inline-flex justify-end items-center">
                            <PrimaryEmblemRightButton 
                            text="DEFEND" 
                            Emblem={DefenceEmblem}
                            onClick={() => dispatch(goTo({ route: routeNames.battleList, args: {} }))} />
                        </div>
                    </div>
                </div>}
            </div>

        </div>
    )
}

export default CountryPreview;