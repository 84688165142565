import { createSlice } from '@reduxjs/toolkit'

export const routeNames = {
    home: "/",
    market: "market",
    stockUpdate: "stockUpdate",
    barracksUpgrade: "barracksUpgrade",
    country: "country",
    landUpgrade: "landUpgrade",
    battleChoose: "battleChoose",
    battle: "battle",
    battleHistory: "battleHistory",
    battlePrepare: "battlePrepare",
    battleList: "battleList",
    battleSelectCounrty: "battleSelectCounrty",
    battleReplay:"battleReplay",
    battleView:"battleView",
    battleDefenceFill:"battleDefenceFill",
    trainingUpgrade:    "trainingUpgrade",
}

const initialState = {
    currentPage: {route: routeNames.home, args:{}},
    previousPages: [],
    currentSlide: 0,
    headerVisible:false
}

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
        changeSlide(state, action){
            state.currentSlide = action.payload;
        },
        goTo(state,action){
            state.previousPages.push(state.currentPage);
            state.currentPage = action.payload;
        },
        goBack(state){
            state.currentPage = state.previousPages.pop();
        },
        goHome(state){
            state.currentPage = {route:"/", args:{}};
            state.previousPages = [];
        },
        setHeaderVisible(state,action){
            state.headerVisible = action.payload;
        }
    },    
});

export const {
    changeSlide,
    setHeaderVisible,
    goTo,
    goBack,
    goHome
} = navigationSlice.actions

export default navigationSlice.reducer