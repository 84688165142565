
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../../app/kit/buttons";
import CountryHeader from "../Country/CountryHeader";
import { goBack } from "../../app/slices/navigationSlice";
import { useEffect, useState } from "react";
import { HeaderOutlet } from "../../app/kit/HeaderOutlet";
import { AutoTextSize } from "auto-text-size";

const CountryPage = () => {
  const user = useSelector(x => x.user);
  return (
    <div className="w-full h-full flex flex-col gap-2">
      <HeaderOutlet>
        <div className="h-12 w-full inline-flex justify-center items-center">
          <div className="w-32">
          <AutoTextSize mode="oneline">{user.country.toUpperCase()}</AutoTextSize>
          </div>        
        </div>
      </HeaderOutlet>
      <CountryHeader />
    </div>
  )
}

export default CountryPage;