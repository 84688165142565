import { Icon } from "@iconify/react/dist/iconify.js"
import { classNames, PrimaryButton } from "../../app/kit/buttons"
import { NxpsIcon } from "../../icons/Nxps"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import axios from "axios"
import { settings } from "../../settings"
import { upgradeStock } from "../../app/slices/dataSlice"
import { Fireworks } from 'fireworks-js'
import { formNumber } from "../../app/helpers/numberFormatter"

const BarracksUpdateInfoBlock = ({ nextLevel, level }) => {
    const balance = useSelector(x => x.data.balance);
    return (
        <div className="w-max flex flex-col gap-4 relative bg-secondaryBg  rounded-lg px-4">
            <div className="flex flex-row gap-5 self-center pt-4 px-2">
                <div className="flex flex-col gap-3">
                    <p className="text-lg font-medium">LVL. {level.level}</p>
                    <p className=" font-medium">CAP. {level.capacity}</p>
                </div>
                <div className="inline-flex justify-center items-center">
                    <Icon icon="ion:arrow-undo-outline" className="h-16 w-16 rotate-[135deg] -mr-3" />
                </div>
                <div className="flex flex-col gap-3">
                    <p className="text-lg text-right  font-medium">LVL. {nextLevel?.level}</p>
                    <p className="text-right  font-medium">CAP. {nextLevel?.newCapacity}</p>
                </div>
            </div>
            <div className="flex flex-row justify-center">
                <div className="inline-flex gap-1 items-center pb-2">
                    <NxpsIcon className="h-10 w-10" />
                    <p className="text-md font-medium">{nextLevel?.price} ({formNumber(balance.tokenAmount)})</p>
                </div>
            </div>
        </div>
    )
}

const BarracksUpdateMainBlock = () => {
    const balance = useSelector(x => x.data.balance);
    const [nextLevel, setNextLevel] = useState({});
    const [fireworkActive, setFireworkActive] = useState(false);
    const capacity = useSelector(x => x.data.barracks.currentCapacity);
    const level = useSelector(x => x.data.barracks.level);

    const getNextLevelInfo = () => {
        axios.get(settings.backendUrl + "/barracks/levelInfo?level=" + (level + 1)).then((res) => {
            setNextLevel(res.data);
        })
    };

    useEffect(() => {
        getNextLevelInfo();
    }, [level])

    const dispatch = useDispatch();

    const onUpdate = () => {
        axios.post(settings.backendUrl + "/barracks/upgrade").then((res) => {
            dispatch(upgradeStock(res.data));
            setFireworkActive(true);
        });
    }

    const [fireworks, setFireworks] = useState(null);

    useEffect(() => {
        const container = document.querySelector('.firework')
        setFireworks(new Fireworks(container, { /* options */ }));
    }, [])

    useEffect(() => {
        if (fireworkActive && fireworks) {
            fireworks.start();
            setTimeout(() => {
                setFireworkActive(false);
                fireworks.stop();
            }, 3000);
        }
    }, [fireworkActive, fireworks])

    return (
        <div className="flex flex-col w-full px-2">           
            <div className="w-full relative inline-flex justify-center items-center">
                <BarracksUpdateInfoBlock level={{ level, capacity }} nextLevel={nextLevel} />
                <div className="firework absolute w-full h-full top-0 left-0" />
            </div>
            <div className="inline-flex w-full justify-center pt-5">
                <PrimaryButton text="UPGRADE" 
                className="!text-xl !py-3 !px-4 font-bold"
                onClick={onUpdate} disabled={balance.tokenAmount < nextLevel.price} />
            </div>

        </div>
    )
}

const BarracksUpdateHeader = () => {
    return (
        <div className="w-full relative pt-6 pb-6">
            <p className="text-6xl w-full text-center">BARRACKS</p>
        </div>
    )
}

const BarracksUpdate = () => {

    return (
            <div className="flex flex-col w-full h-max relative bg-cover bg-no-repeat bg-center pb-5"  style={{ backgroundImage: 'url(barracks.jpg)' }}>
                <BarracksUpdateHeader />
                <BarracksUpdateMainBlock />
            </div>
    )
}

export default BarracksUpdate;