import { classNames } from "../app/kit/buttons";
import attackEmblem from "./Emblems/emblem_attack.png"
import defendEmblem from "./Emblems/emblem_defend.png"

export const AttackEmblem = ({className})=>{
    return(
        <img src={attackEmblem} className={className}/>
    )
}

export const DefenceEmblem = ({className})=>{
    return(
        <img src={defendEmblem} className={className}/>
    )
}