import { useDispatch, useSelector } from "react-redux";
import { StatContainer } from "../../app/kit/containers";
import { resourceToIcon } from "../Stock/StockPreview";
import { PrimaryButton, PrimaryRightButton } from "../../app/kit/buttons";
import { formNumber } from "../../app/helpers/numberFormatter";
import { goTo, routeNames } from "../../app/slices/navigationSlice";
import { AutoTextSize } from "auto-text-size";
import { forceToIcon } from "../Barracks/BarracksPreview";

const NftView = ({ nft }) => {
    const free = nft.nftAddress.length === 36;
    const isTraining= nft.trainingType !== undefined && nft.trainingType !== null;
    console.log(nft);
    const dispatch = useDispatch();
    return (
        <div
            className="h-40 relative w-full woodenborder bg-cover bg-no-repeat bg-center" style={{ backgroundImage: `url(${nft.nftImage})` }}>
            <div className="flex flex-col w-full h-full justify-between">
                <div className="flex flex-row justify-between p-1 text-black/70">
                    <div className="w-full max-w-[50%] flex flex-row gap-1 items-start -md p-1 px-5 bg-no-repeat" 
                    style={{ backgroundImage: "url(papiero1.png)", backgroundSize: "100% 100%" }}>
                        <div className="w-full h-full inline-flex items-center justify-center">
                        <AutoTextSize mode="boxoneline" className="self-center">
                            {isTraining ?nft.trainingType.toUpperCase() : nft.landType.toUpperCase()}{free?" (FREE)":""}
                        </AutoTextSize>
                        </div>
                    </div>
                    <div className="w-max inline-flex items-center">
                        <div className="bg-red-900 text-white p-1 w-max -mr-2 rightLabelMark">
                            <p className="text-lg font-medium pl-2">LVL {nft.level}</p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row w-full justify-between px-1">
                    <div className="w-max">
                        <StatContainer text={`${formNumber(nft.hourlyIncome)}/h`}
                            className="pr-2"
                            icon={isTraining? forceToIcon(nft.trainingType): resourceToIcon(nft.resourceType)} />
                    </div>

                    <div className="w-fill inline-flex pb-3 justify-end">
                        <PrimaryRightButton text="MANAGE" onClick={() => dispatch(goTo({ route: routeNames.landUpgrade, args: { nftAddr: nft.nftAddress } }))} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NftView;