import axios from "axios"
import { settings } from "../../settings"
import { useEffect, useState } from "react";
import { secondsToHumanReadable } from "../Balances/BalanceView";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryRightButton } from "../../app/kit/buttons";
import { goTo, routeNames } from "../../app/slices/navigationSlice";


const BattleSlotPlank = ({ battleSlot }) => {

    const [left, setLeft] = useState(Math.floor((new Date(battleSlot.targetedTillUtc) - Date.now()) / 1000));

    useEffect(() => {
        const interval = setInterval(() => {
            setLeft(Math.floor((new Date(battleSlot.targetedTillUtc) - Date.now()) / 1000));
        }, 1000);
        return () => clearInterval(interval);
    }, [setLeft, battleSlot.targetedTillUtc])

    const dispatch = useDispatch();
    return (
        <div className="w-max inline-flex px-2 py-1 bg-no-repeat" style={{ backgroundImage: "url(papiero1.png)", backgroundSize: "100% 100%" }}>
            <p>{battleSlot.attackerCountry}</p>
            <p className="ml-2">{battleSlot.sumAmount}</p>
            <p className="ml-2 mr-2">{secondsToHumanReadable(left)}</p>
            <PrimaryRightButton size="small" text={"DEFEND"} onClick={()=>dispatch(goTo({ route: routeNames.battleView, args: {battleId:battleSlot.battleSlotId} }))}/>
        </div>
    )
}

const BattleListPage = () => {
    const [battleList, setBattleList] = useState([]);
    const dispatch = useDispatch();
    const requestList = () => {
        axios.get(settings.backendUrl + "/battle/list").then(res => {
            if (res.data.battleViewResponse !== null){
                dispatch(goTo({ route: routeNames.battleDefenceFill, args: { battle: res.data.battleViewResponse } }))
            }
            else{
                setBattleList(res.data.countryAttacks);
            }            
        });
    }

    useEffect(() => {
        requestList();
    }, [])



    return (
        <div className="w-full h-full flex flex-col gap-2 pt-2 text-black/70">
            {battleList.map(x => (
                <div key={x.battleSlotId}  className="w-full inline-flex justify-center">
                    <BattleSlotPlank battleSlot={x} />
                </div>
            ))}
        </div>
    )
}

export default BattleListPage;