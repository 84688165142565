import { useEffect, useState } from 'react';
import Entrypoint from './Entrypoint';
import { useDispatch, useSelector } from 'react-redux';
import { useUser } from './user/useUser';
//APi token: testnet.toncenter.com 282697102c08842d840f1271f37d2a3cc5d5b892f4498651a7e71b3ba30eb41b
import eruda from "eruda";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Icon } from '@iconify/react/dist/iconify.js';
import { goBack, goHome, goTo, routeNames } from './app/slices/navigationSlice';
import { tryVibrate } from './app/helpers/vibrateHelper';

eruda.init();

const NavBar = () => {
  const user = useSelector(x => x.user);
  const dispatch = useDispatch();

  const canBack = useSelector(x => x.navigation.previousPages.length > 0);

  const onBack = () => {
    if (!canBack) return;
    tryVibrate();
    dispatch(goBack());
  }

  const onHome = () => {
    tryVibrate();
    dispatch(goHome());
  }

  const onCountry = () => {
    tryVibrate();
    dispatch(goTo({ route: routeNames.country, args: {} }));
  }

  const onBattleHistory = ()=>{
    tryVibrate();
    dispatch(goTo({ route: routeNames.battleHistory, args: {} }));
  }

  const currentPage = useSelector(x => x.navigation.currentPage.route);
  const homePage = currentPage === routeNames.home;

  return user.initialized && user.loggedIn && user.country !== null ? (
    <div className={`absolute bottom-0 w-full text-white`}>
      <div className="w-full inline-flex items-center justify-between">
        <button className={`w-full inline-flex p-2 justify-center pb-8 pt-3 bg-secondaryBg  backdrop-blur-sm ${canBack?"":"text-slate-500"}`} disabled={!canBack} onClick={onBack}>
          <Icon icon="raphael:arrowleft" className="w-6 h-6" />
        </button>
        {homePage ?
          <button className="w-full inline-flex p-2 justify-center pb-8 pt-3 bg-secondaryBg  backdrop-blur-sm" onClick={onCountry}>
            <Icon icon="mdi:world" className="w-6 h-6" />
          </button> :
          <button className="w-full inline-flex p-2 justify-center pb-8 pt-3 bg-secondaryBg  backdrop-blur-sm">
            <Icon icon="uis:house-user" className="w-6 h-6" onClick={onHome} />
          </button>
        }
        <button className="w-full p-2 inline-flex justify-center pb-8 pt-3 bg-secondaryBg  backdrop-blur-sm" onClick={onBattleHistory}>
          <Icon icon="ic:round-history" className="w-6 h-6" />
        </button>
      </div>
    </div>
  ) : (<></>)
}

function App() {

  const appReady = useSelector(x => x.user.initialized);

  useUser();
  return (
    <div className="h-full flex flex-row justify-center max-w-2xl h-full mx-auto relative text-white">
      {appReady ?
        <Entrypoint /> :
        <div>Loading...</div>
      }
      <NavBar />
    </div>
  )

}

export default App;
