import { Icon } from "@iconify/react/dist/iconify.js";
import { useDispatch, useSelector } from "react-redux";
import { recalcStock } from "../../app/slices/dataSlice";
import { useEffect } from "react";
import { PrimaryButton, PrimaryRightButton } from "../../app/kit/buttons";
import { StatContainer } from "../../app/kit/containers";
import { formNumber } from "../../app/helpers/numberFormatter";
import { goTo, routeNames } from "../../app/slices/navigationSlice";
import { AutoTextSize } from "auto-text-size";
import { BricksIcon, GrainIcon, StoneIcon, WoodIcon } from "../../icons/ResourceIcons";

export const resourcesMap = {
    Stone: "Stone",
    Wood: "Wood",
    Grain: "Grain",
    Bricks: "Bricks"
}

export const resourceToIcon = (resourceType) => {
    switch (resourceType) {
        case resourcesMap.Stone:
            return { CustomIcon: StoneIcon };
        case resourcesMap.Wood:
            return { CustomIcon: WoodIcon };
        case resourcesMap.Grain:
            return { CustomIcon: GrainIcon };
        case resourcesMap.Bricks:
            return { CustomIcon: BricksIcon };
    }

}

const StockResourceBlock = () => {
    const stock = useSelector((state) => state.data.runtime.stock);
    return (
        <div className="flex flex-col gap-1 w-max">
            {stock.resources.map(x => <StatContainer text={formNumber(x.amount)} icon={resourceToIcon(x.resourceType)} />)}
        </div>
    )
}

const StockAmountBlock = () => {
    const { currentAmount, currentCapacity } = useSelector((state) => state.data.runtime.stock);
    return (
        <StatContainer text={`${formNumber(currentAmount)} / ${formNumber(currentCapacity)}`} textClassName="!text-md" className="w-max text-nowrap px-2" />
    )
}

const StockProgressBar = () => {

    const { currentAmount, currentCapacity } = useSelector((state) => state.data.runtime.stock);
    const load = 100 * currentAmount / currentCapacity;



    return (
        <div className="h-full w-3 overflow-hidden rounded-full bg-backlight/70 flex flex-col justify-end">
            <div className="rounded-full bg-accent" style={{ height: `${load}%` }} />
        </div>
    )
}

const StockPreview = () => {
    const level = useSelector((state) => state.data.stock.level);
    const dispatch = useDispatch();
    return (
        <div className="flex justify-center h-full w-full bg-cover bg-no-repeat" style={{ backgroundImage: "url(Warehouse.jpg)" }}>
            <div className="flex flex-col w-full pl-1">
                <div className="flex inline-flex w-full items-center h-12 justify-between pr-1">
                    <div className="py-2">
                        <StockAmountBlock />
                    </div>
                    <div className="w-full h-full flex items-center justify-end pl-4">
                        <AutoTextSize className="self-center text-right text-white opacity-70 " mode="box">
                            WAREHOUSE
                        </AutoTextSize>
                    </div>
                </div>
                <div className="flex w-full h-full justify-between">
                    <div className="flex w-full h-full">
                        <StockProgressBar />
                        <StockResourceBlock />
                    </div>
                    <div className="flex flex-col w-full h-full justify-between">
                        <div className="inline-flex w-full justify-end">
                            <PrimaryRightButton text={"LVL " + level}
                                icon="pepicons-pencil:hammer-claw"
                                onClick={() => dispatch(goTo({ route: routeNames.stockUpdate, args: {} }))} />
                        </div>
                        <div className="inline-flex justify-end pb-2">
                            <PrimaryRightButton text="EXCHANGE" icon="ph:scales"
                                onClick={() => dispatch(goTo({ route: routeNames.market, args: {} }))} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StockPreview;