
import { useDispatch } from "react-redux";
import { PrimaryButton } from "../../app/kit/buttons";
import MarketHeader, { MarketResourceList } from "../Market/Market";
import { goBack } from "../../app/slices/navigationSlice";



const MarketPage = () => {
    
    return (
        <div className="w-full h-full flex flex-col gap-2 relative">
            <MarketHeader />
            <MarketResourceList />
        </div>
    )
}

export default MarketPage;