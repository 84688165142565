import { Icon } from "@iconify/react/dist/iconify.js"
import { useState } from "react"
import { resourceToIcon } from "../../components/Stock/StockPreview"
import { classNames, PrimaryButton } from "./buttons"
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { forceToIcon } from "../../components/Barracks/BarracksPreview"

const ListResourceInput = ({ resource, setResource, options }) => {

    return (
        <Listbox value={resource} onChange={setResource}>
            <div className="relative">
                <ListboxButton className="bg-mainBg text-white px-2 py-1 flex items-center rounded-r-md w-full h-full">
                    <div className="inline-flex items-center">
                        <Icon icon={resourceToIcon(resource).text} className={classNames(resourceToIcon(resource).className, "h-6 w-6")} />
                        <Icon icon="mingcute:down-fill" />
                    </div>
                </ListboxButton>
                <ListboxOptions
                    transition
                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-mainBg py-1 text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                >
                    {options.map((x) => (
                        <ListboxOption
                            key={x}
                            value={x}
                            className={classNames("group relative cursor-default select-none py-2 px-2", resource === x ? "bg-secondaryBg" : "bg-mainBg")}
                        >
                            <Icon icon={resourceToIcon(x).text} className={classNames(resourceToIcon(x).className, "h-5 w-5")} />
                        </ListboxOption>
                    ))}
                </ListboxOptions>
            </div>
        </Listbox>
    )
}

const ListForceInput = ({ force, setForce, options }) => {

    return (
        <Listbox value={force} onChange={setForce}>
            <div className="relative">
                <ListboxButton className="bg-mainBg text-white px-2 py-1 flex items-center rounded-r-md w-full h-full">
                    <div className="inline-flex items-center">
                        <Icon icon={forceToIcon(force).text} className={classNames(forceToIcon(force).className, "h-6 w-6")} />
                        <Icon icon="mingcute:down-fill" />
                    </div>
                </ListboxButton>
                <ListboxOptions
                    transition
                    className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-mainBg py-1 text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                >
                    {options.map((x) => (
                        <ListboxOption
                            key={x}
                            value={x}
                            className={classNames("group relative cursor-default select-none py-2 px-2", force === x ? "bg-secondaryBg" : "bg-mainBg")}
                        >
                            <Icon icon={forceToIcon(x).text} className={classNames(forceToIcon(x).className, "h-5 w-5")} />
                        </ListboxOption>
                    ))}
                </ListboxOptions>
            </div>
        </Listbox>
    )
}

export const ComboResourceInput = ({ resource, setResource, amount, setAmount, options, maxAmountClick }) => {
    
    return (
        <div className="flex flex-row rounded-md  bg-mainBg -md">
            <div className="relative w-full">
                <input type="number" className="w-full bg-transparent text-white rounded-md px-3 py-2"
                    value={amount}
                    pattern="[0-9]+([\.,][0-9]+)?" step="0.0001"
                    onChange={(e) => setAmount(e.target.value)}
                />
                <div className="absolute z-60 right-0 bottom-1/2 translate-y-1/2 pr-2">
                    <PrimaryButton text="MAX" size="small" onClick={maxAmountClick} />
                </div>
            </div>
            <ListResourceInput resource={resource} setResource={setResource} options={options} />
        </div>
    )
}

export const ComboForceInput = ({ force, setForce, amount, setAmount, options, maxAmountClick }) => {
    
    return (
        <div className="flex flex-row rounded-md  bg-mainBg -md">
            <div className="relative w-full">
                <input type="number" className="w-full bg-transparent text-white rounded-md px-3 py-2"
                    value={amount.toFixed(0)}
                    step="1"
                    onChange={(e) => setAmount(Number(e.target.value))}
                />
                <div className="absolute z-60 right-0 bottom-1/2 translate-y-1/2 pr-2">
                    <PrimaryButton text="MAX" size="small" onClick={maxAmountClick} />
                </div>
            </div>
            <ListForceInput force={force} setForce={setForce} options={options} />
        </div>
    )
}

/*
 <button className="bg-gray-900/70 text-white px-2 py-1 flex items-center rounded-r-md">
                <div className="inline-flex items-center">
                    <Icon icon={resourceToIcon(resource).icon} className={classNames(resourceToIcon(resource).className, "h-6 w-6")} />
                    <Icon icon="mingcute:down-fill" />
                </div>
            </button>
*/