import { useMemo, useState } from "react"
import { ComboResourceInput } from "../../app/kit/comboInput"
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../../app/kit/buttons";
import axios from "axios";
import { settings } from "../../settings";
import { investComplete, loadCountryRatings } from "../../app/slices/dataSlice";




export const InvestResourceModal = ({ onClose, data }) => {
   
    const userResources = useSelector((state) => state.data.runtime.stock.resources);
    const userResDict = useMemo(() => userResources.reduce((acc, x) => { acc[x.resourceType] = x; return acc; }, {}), [userResources]);
    const userResourceTypes = useMemo(() => userResources.map(x => x.resourceType), [userResources]);
    
    const level = useSelector(x => x.data.countries[data.countryName].currentLevel);
    const [investment, setInvestment] = useState({
        amount: 0,
        resource: data.resourceType,
    });
    const requirements = useSelector(x => x.data.countries[data.countryName].resourceRequirements);
    const resources = useSelector(x => x.data.countries[data.countryName].resources);

    const onSetAmount = (amount) => {
        setInvestment(x => {
            let currentAmount = userResources.find(resource => resource.resourceType === x.resource)?.amount;
            let newAmount = amount > currentAmount ? currentAmount : amount;
            return { ...x, amount: newAmount }
        });
    }

    const onSetResource = (resource) => {
        setInvestment(x => {
            let currentAmount = userResources.find(resource => resource.resourceType === resource)?.amount;
            let newAmount = x.amount > currentAmount ? currentAmount : x.amount;
            return { ...x, resource: resource, amount: newAmount }
        });
    }

    const onMax = () => {
        setInvestment(x => {
            let currentAmount = userResources.find(resource => resource.resourceType === x.resource)?.amount;
            return { ...x, amount: currentAmount }
        });
    }
    const dispatch = useDispatch();

    const getRatings = () => {
        axios.get(settings.backendUrl + "/country/ratings").then(res => {
            dispatch(loadCountryRatings(res.data));
        });
    }

    const onInvest = ()=>{
        axios.post(settings.backendUrl + "/country/invest",{
            level: level,
            resourceType: investment.resource,
            amount: investment.amount,
        }).then(res => {
            if (res.status === 200) {
                dispatch(investComplete(res.data));
                getRatings();
                onClose();
            }
        })
    }

    return (
        <div className="w-full flex flex-col">
            <div className="inline-flex gap-1 pt-2">
                {requirements[investment.resource] === undefined ? <p>Not required</p> :
                    <>
                        <p>Total required: </p>
                        <PrimaryButton text={requirements[investment.resource].amount - (resources[investment.resource]?.amount || 0)} size="small"
                            onClick={() => onSetAmount(requirements[investment.resource].amount - (resources[investment.resource]?.amount || 0))} />
                    </>
                }
            </div>
            <div className="pt-3">
                <ComboResourceInput
                    amount={investment.amount}
                    options={userResourceTypes}
                    setAmount={onSetAmount}
                    setResource={onSetResource}
                    maxAmountClick={onMax}
                    resource={investment.resource} />
            </div>
            <div>
            <p>You have: {userResDict[investment.resource]?.amount}</p>
            </div>
            <div className="w-full inline-flex justify-center pt-4">
                <PrimaryButton disabled={requirements[investment.resource] === undefined || 
                resources[investment.resource]?.amount >= requirements[investment.resource].amount ||
                ((requirements[investment.resource].amount - resources[investment.resource]?.amount) >= 1 && investment.amount < 1)} text="INVEST" onClick={onInvest} />
            </div>
        </div>
    )
}