

export const formNumber = (number)=>{
    var nmbr = number;
    if (nmbr === 0)return 0;
    if (nmbr < 1){
        return formSmallNumber(nmbr);
    }
    if (nmbr < 10){
        nmbr = Math.floor(nmbr*1000)/1000;        
        return nmbr;
    }
    if (nmbr < 100){
        nmbr = Math.floor(nmbr*100)/100;        
        return nmbr;
    }
    if (nmbr < 1000){
        nmbr = Math.floor(nmbr);        
        return nmbr;
    }
    if (nmbr < 1000000){
        nmbr = Math.floor(nmbr/1000);
        return formNumber(nmbr)+"K";
    }
    return nmbr;
}

const formSmallNumber = (smallNumber)=>{
    var smallnmbr = smallNumber;
    if (smallnmbr < 0.001){
        smallnmbr = Math.floor(smallnmbr*10000)/10000;
        return smallnmbr;
    }    
    if (smallnmbr < 0.01){
        smallnmbr = Math.floor(smallnmbr*1000)/1000;
        return smallnmbr;
    }
    if (smallnmbr < 0.1){
        smallnmbr = Math.floor(smallnmbr*1000)/1000;
        return smallnmbr;
    }
    if (smallnmbr < 1){
        smallnmbr = Math.floor(smallnmbr*1000)/1000;
        return smallnmbr;
    }
}