import { useDispatch, useSelector } from "react-redux";
import { InvestResourceModal } from "./InvestResourceModal"
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { closeModal } from "../../app/slices/modalSlice";
import { PrimaryButton } from "../../app/kit/buttons";
import { WinOrLooseModal } from "./WinOrLooseModal";

export const Modals = {
  InvestResourceModal: "InvestResourceModal",
  WinOrLooseModal: "WinOrLooseModal",
}

export const ModalsContent = {
  [Modals.InvestResourceModal]: InvestResourceModal,
  [Modals.WinOrLooseModal]: WinOrLooseModal,
}


export const ModalOutlet = ({ children }) => {

  const modal = useSelector(state => state.modals.modal);
  const ModalInnerComponent = modal !== null ? ModalsContent[modal.id] : null;
  const dispatch = useDispatch();
  const onModalClose = () => {
    dispatch(closeModal());
  }
  return (
    <div className="min-w-full h-full">
      <div className="top-0 left-0 h-full w-full">
        <div className="flex flex-col w-full h-full">
          {children}
        </div>
        {modal !== null &&
          <ModalDialog onClose={onModalClose} title={modal.title}>
            <ModalInnerComponent onClose={onModalClose} data={modal} />
          </ModalDialog>
        }
      </div>
    </div>
  )
}

export const ModalDialog = (props) => {
  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={props.onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-opacity-75 bg-gray-900 transition-opacity " />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex flex-col h-full items-end justify-center p-4 text-center items-center p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="h-full relative flex flex-col gap-2 justify-center items-center transform overflow-hidden rounded-lg bg-gray-900/70 text-white px-4 pb-4 pt-5 text-left shadow-xl transition-all my-8 w-full max-w-xl p-6">

                <div className="inline-flex w-full justify-between items-center">
                  <p className="text-lg ">{props.title}</p>
                  <PrimaryButton icon="material-symbols:close" className="py-3" onClick={props.onClose} />
                </div>
                <div className="w-full h-full flex flex-col gap-2 min-h-72 justify-center items-center">

                  {props.children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>)
}