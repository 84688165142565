import { TonConnectButton } from "@tonconnect/ui-react";
import { useBackendAuth } from "./user/auth";
import { useDispatch, useSelector } from "react-redux";
import MainFrame from "./components/Main";
import CountrySelector from "./components/Onboarding/CountrySelector";
import { setHeaderVisible } from "./app/slices/navigationSlice";


const Entrypoint = () => {

    useBackendAuth();
    const dispatch = useDispatch();
    const visible = useSelector(x => x.navigation.headerVisible);
    const user = useSelector(x => x.user);
    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const position = Math.ceil(
            (scrollTop / (scrollHeight - clientHeight)) * 100
        );
        if (position <= 80 && visible) {
            dispatch(setHeaderVisible(false));
        } else
            if (position > 80 && !visible) {
                dispatch(setHeaderVisible(true));
            }
    }
    return (
        <div className="w-full h-full bg-mainBg">
            <div className={`sm:border-2 w-full h-full sm:rounded-md overflow-y-auto overscroll-none touch-pan-y sm:border-mainBr`}
                onScroll={handleScroll}>
                {user.loggedIn ?
                    user.country === null ?
                        <CountrySelector />
                        : <MainFrame />
                    :
                    <div className="w-full flex flex-col gap-5 h-1/2 justify-center items-center">
                        <div>Log in with TON Wallet</div>
                        <TonConnectButton />
                    </div>
                }
            </div>
        </div>
    );
}

export default Entrypoint;