import axios from "axios";
import { settings } from "../../settings";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { selectCountry } from "../../app/slices/userSlice";
import { Icon } from "@iconify/react/dist/iconify.js";
import { tryVibrate } from "../../app/helpers/vibrateHelper";
import { AutoTextSize } from "auto-text-size";

const CountrySelectorNameBlock = ({countryName}) => {

    return (
        <div className="w-full text-white pt-1 px-2">
            <AutoTextSize mode="oneline">
            {countryName.toUpperCase()}
            </AutoTextSize>
        </div>
    )
}

const CountrySelectBox = ({ country, setCountry, selected }) => {

    const dispatch = useDispatch();
    const selectCountryStep = (country) => {
        axios.post(settings.backendUrl + "/selectCountry", { countryName: country.name }).then(res => {
            dispatch(selectCountry(res.data.countryName))
        });
    }
    console.log("Country", country);
    return (

        <div className="shadow-lg flex flex-col gap-2">
            <div className={`h-40 w-full rounded-md relative cursor-pointer hover:shadow-lg ${selected ? "border-accent border-2":""}`} onClick={() => {tryVibrate(); setCountry(country.name)}}>
                <div className="absolute w-full left-0 top-0 pt-2 px-5 z-10">
                    <div className="flex flex-col w-full">
                        <div className="w-max text-nowrap pr-2 text-md font-bold text-white">
                            <span className="font-bold text-md">LVL {country.level}</span>
                        </div>
                        <CountrySelectorNameBlock countryName={country.name} />
                    </div>
                </div>
                {selected && <div className="absolute inline-flex justify-center bottom-0 w-full rounded-t-md pb-2">
                    <button
                    onClick={() => {tryVibrate(); selectCountryStep(country)}}
        type="button"
        className="inline-flex items-center gap-x-2 rounded-md bg-accent px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-accent"
      >
        <Icon icon="lets-icons:check-fill" />
        Confirm
      </button>
                    </div>}
                <img src={`${country.name}.png`} className="h-full w-full object-cover rounded-t-md" />
            </div>
        </div>
    )
}

const CountrySelector = () => {

    const [countryList, setCountryList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [selectedCountry, setSelectedCountry] = useState(null);
    console.log("Country selector");
    const getCountryList = () => {
        axios.get(settings.backendUrl + "/countries").then(res => {
            setCountryList(res.data);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getCountryList();
    }, []);


    const setCountry = (countryName)=>{
        setSelectedCountry(countryName);
    }
    console.log("Country list", countryList);
    return (
        <div className="px-2 flex flex-col min-h-60 justify-center gap-5 pt-5 bg-red">
            {isLoading ? "Loading..." :
                <>
                <div className="text-center w-full flex flex-col gap-1">
                <div className="text-md font-bold">Select country</div>
                <div className="text-md">Please note, that you won't be able to change country later</div>
                </div>                    
                    {countryList.map(x =>
                        <CountrySelectBox country={x} setCountry={setCountry} selected={selectedCountry===x.name}/>
                    )}
                </>
            }
        </div>
    )
}

export default CountrySelector;