import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    lands:{},
    trainings:{},
    countries: {},
    stock: {},
    barracks: {},
    balance: {},
    isLoaded: false,
    runtime: {
        stock: {},
        barracks: {}
    }
}

const recalcStockInternal = (state) => {
    //TODO: Optimize by calling it only from pages where it is needed
    const capacity = state.stock.currentCapacity;
    const filled = state.stock.currentAmount;
    const allowed = capacity - filled;
    state.runtime.stock = { ...state.stock };
    if (allowed <= 0) {
        state.runtime.stock.currentAmount = filled;
        return;
    }
    const timePassedInHours = (new Date() - state.stock.lastLoad) / 1000 / 3600;

    const hourlyIncomeDict = {};

    Object.keys(state.lands).forEach(nftAddress => {
        const nft = state.lands[nftAddress];
        const resourceType = nft.resourceType;
        const hourlyIncome = nft.hourlyIncome;
        hourlyIncomeDict[resourceType] = hourlyIncome;
    });

    const incomeDict = {};
    state.stock.resources.forEach(resource => incomeDict[resource.resourceType] = (hourlyIncomeDict[resource.resourceType] ?? 0) * timePassedInHours);
    //Update resource quantities based on hourly income
    const totalIncome = Object.values(incomeDict).reduce((acc, x) => acc + x, 0);
    const factor = totalIncome < allowed ? 1 : allowed / totalIncome;
    state.runtime.stock.resources = state.stock.resources.map(x => { return { ...x, amount: x.amount + factor * incomeDict[x.resourceType] } })
    state.runtime.stock.currentAmount = filled + totalIncome * factor;
}

const recalcbarracksInternal = (state) => {
    //TODO: Optimize by calling it only from pages where it is needed
    const capacity = state.barracks.currentCapacity;
    const filled = state.barracks.currentAmount;
    const allowed = capacity - filled;
    state.runtime.barracks = { ...state.barracks };
    if (allowed <= 0) {
        state.runtime.barracks.currentAmount = filled;
        return;
    }
    const timePassedInHours = (new Date() - state.barracks.lastLoad) / 1000 / 3600;

    const hourlyIncomeDict = {};
    Object.keys(state.trainings).forEach(nftAddress => {
        const nft = state.trainings[nftAddress];
        
        const forceType = nft.forceType;
        const hourlyIncome = nft.hourlyIncome;
        hourlyIncomeDict[forceType] = hourlyIncome;
    });
    
    const incomeDict = {};
    state.barracks.forces.forEach(force => incomeDict[force.forceType] = (hourlyIncomeDict[force.forceType] ?? 0) * timePassedInHours);
    //Update resource quantities based on hourly income
    const totalIncome = Object.values(incomeDict).reduce((acc, x) => acc + x, 0);
   
    const factor = totalIncome < allowed ? 1 : allowed / totalIncome;
    //TODO: refactor it! When not enough space in barracks for update - call server to update barracks instead
    state.runtime.barracks.forces = state.barracks.forces.map(x => { return { ...x, amount: x.amount + factor * incomeDict[x.forceType] } })
    state.runtime.barracks.currentAmount = state.runtime.barracks.forces.reduce((acc, x) => acc + Math.floor(x.amount), 0);
}

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        loadUpdate(state, action) {
            const landList = action.payload.lands;
            const landMap = {};

            landList.forEach(nft => {
                landMap[nft.nftAddress] = nft;
            });


            state.lands = landMap;


            const trainingList = action.payload.trainings;
            const trainingMap = {};

            trainingList.forEach(nft => {
                trainingMap[nft.nftAddress] = nft;
            });


            state.trainings = trainingMap;
            //TEMP
            if (!state.countries[action.payload.country.name]) {
                state.countries[action.payload.country.name] = {};
            }
            state.countries[action.payload.country.name].currentLevel = action.payload.country.level;
            state.countries[action.payload.country.name].name = action.payload.country.name;
            state.countries[action.payload.country.name].progress = action.payload.country.progress;

            state.stock = {
                ...action.payload.stock,
                currentAmount: action.payload.stock.resources.reduce((acc, x) => acc + x.amount, 0),
                lastLoad: new Date()
            };

            state.barracks = {
                ...action.payload.barracks,
                currentAmount: action.payload.barracks.forces.reduce((acc, x) => acc + x.amount, 0),
                lastLoad: new Date()
            };

            state.balance = action.payload.balance;
            recalcStockInternal(state);
            recalcbarracksInternal(state);
            state.isLoaded = true;
        },
        recalcStock(state, action) {
            if (!state.isLoaded) return;
            recalcStockInternal(state);
        },
        recalcbarracks(state, action) {
            if (!state.isLoaded) return;
            recalcbarracksInternal(state);
        },
        upgradeStock(state, action) {
            state.balance.tokenAmount = action.payload.balance;
            state.stock.currentCapacity = action.payload.newCapacity;
            state.stock.level = action.payload.newLevel;
        },
        upgradebarracks(state, action) {
            state.balance.tokenAmount = action.payload.balance;
            state.stock.currentCapacity = action.payload.newCapacity;
            state.stock.level = action.payload.newLevel;
        },
        loadCountry(state, action) {
            state.countries[action.payload.countryName] = {
                countryName: action.payload.countryName,
                currentLevel: action.payload.currentLevel,
                progress: action.payload.progress,
                reward: action.payload.reward,
            };
            state.countries[action.payload.countryName].resources = action.payload.resources.reduce((acc, x) => { acc[x.resourceType] = x; return acc; }, {});
            state.countries[action.payload.countryName].resourceRequirements = action.payload.resourceRequirements.reduce((acc, x) => { acc[x.resourceType] = x; return acc; }, {});
        },
        loadCountryRatings(state, action) {
            state.countries[action.payload.countryName].ratings = action.payload;
        },
        dealComplete(state, action) {
            state.stock = {
                ...action.payload.stock,
                currentAmount: action.payload.stock.resources.reduce((acc, x) => acc + x.amount, 0),
                lastLoad: new Date()
            };

            recalcStockInternal(state);
        },
        investComplete(state, action) {
            state.balance.tokenAmount = action.payload.balance;
            state.countries[action.payload.country.countryName] = {
                ...state.countries[action.payload.country.countryName],
                currentLevel: action.payload.country.currentLevel,
                progress: action.payload.country.progress,
                reward: action.payload.country.reward,
            };
            state.countries[action.payload.country.countryName].resources = action.payload.country.resources.reduce((acc, x) => { acc[x.resourceType] = x; return acc; }, {});
            state.countries[action.payload.country.countryName].resourceRequirements = action.payload.country.resourceRequirements.reduce((acc, x) => { acc[x.resourceType] = x; return acc; }, {});

            state.stock = {
                ...action.payload.stock,
                currentAmount: action.payload.stock.resources.reduce((acc, x) => acc + x.amount, 0),
                lastLoad: new Date()
            };

            if (action.payload.country.currentLevel > state.countries[action.payload.country.countryName].currentLevel) {
                state.countries[action.payload.countryName].ratings = null;
            }

            recalcStockInternal(state);
        },
        upgradeLand(state, action) {
            state.nftList[action.payload.nftAddress].hourlyIncome = action.payload.hourlyIncome;
            state.nftList[action.payload.nftAddress].levelUpPrice = action.payload.levelUpPrice;
            state.nftList[action.payload.nftAddress].level = action.payload.newLevel;
            state.balance.tokenAmount = action.payload.balance;
        }
    }
});

export const {
    upgradeStock,
    upgradebarracks,
    loadUpdate,
    recalcStock,
    recalcbarracks,
    loadCountry,
    loadCountryRatings,
    dealComplete,
    investComplete,
    upgradeLand
} = dataSlice.actions

export default dataSlice.reducer