import { configureStore } from '@reduxjs/toolkit';
import userReducer from "./slices/userSlice"
import dataReducer from "./slices/dataSlice"
import modalReducer from "./slices/modalSlice"
import navigationReducer from "./slices/navigationSlice"
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

const persistConfig = {
  key: 'user',
  storage,
  whitelist: ['token']
}

const persistedReducer = persistReducer(persistConfig, userReducer);

export const store = configureStore({
  reducer: {
    data: dataReducer,
    user: persistedReducer,
    modals: modalReducer,
    navigation: navigationReducer
  },
  middleware: (defaultMiddleware) =>
    defaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);
