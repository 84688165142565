import { Icon } from "@iconify/react/dist/iconify.js"
import { classNames } from "./buttons"



export const StatContainer = ({text, icon, className, textClassName, iconClassName})=>{
    return(
        <div className={classNames("w-full flex flex-row gap-2 items-center px-3 py-0.5 text-xs font-medium text-mainBg bg-no-repeat",className)}
        style={{backgroundImage: "url(papiero1.png)", backgroundSize:"100% 100%"}}>
         {icon!==undefined && icon!==null && icon.text!== undefined &&<Icon icon={icon.text} className={classNames("h-5 w-5", icon.className,iconClassName)}/>}
         {icon!==undefined && icon!==null && icon.CustomIcon !== undefined && <icon.CustomIcon className={classNames("h-5 w-5", icon.className,iconClassName)}/>}
        <p className={classNames("text-lg", textClassName)}>{text}</p>
    </div>
    )
}