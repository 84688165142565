import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { persistor, store } from './app/store';
import App from './App';
import './index.css';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import axios from 'axios';
import { PersistGate } from 'redux-persist/integration/react';
import {jwtDecode} from 'jwt-decode';
import moment from 'moment';
import { requestNewToken } from './app/authThunk';
import { settings } from './settings';
import 'react-tooltip/dist/react-tooltip.css'

const container = document.getElementById('root');
const root = createRoot(container);
const { dispatch } = store;
axios.defaults.withCredentials = true;
axios.interceptors.request.use(req => {
  let access_token = store.getState().user.token;
  if (access_token ==="") return req;

  if (!req.url.includes("refresh_token")) {
    const decoded = jwtDecode(access_token);
    if (decoded.exp && decoded.exp - moment().unix() < 1*24*60*60 && decoded.exp - moment().unix()>10){
      dispatch(requestNewToken());
    }    
  }

  req.headers['Authorization'] = `Bearer ${access_token}`;    
  return req;
})


root.render(
  <TonConnectUIProvider manifestUrl={settings.tonManifestUrl}
    actionsConfiguration={{
      twaReturnUrl: settings.twaReturnUrl
    }}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>

  </TonConnectUIProvider>
);

