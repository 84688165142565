import { useDispatch } from "react-redux";
import { recalcbarracks } from "../../app/slices/dataSlice";
import { useEffect } from "react";


export const useBarracksUpdates = ()=>{
    const dispatch = useDispatch();

    const recalcData = () => {
        dispatch(recalcbarracks())
    }

    useEffect(() => {
        const interval = setInterval(() => {
            recalcData();
        }, 2000); // 1 minute in milliseconds
        return () => clearInterval(interval);
    }, [])
}