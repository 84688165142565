const parseQuery = (query) => {
    const queryPairs = query.split('&');
    const queryObject = {};
  
    queryPairs.forEach((pair) => {
      const [key, value] = pair.split('=');
      queryObject[key] = value;
    });
  
    return queryObject;
  };
  
  const unescapeJson = (escapedJson) => {
    if (escapedJson === "" || escapedJson === undefined) {
      return {first_name:"Not connected"}
    }
    const unescapedJson = decodeURIComponent(escapedJson);
    return JSON.parse(unescapedJson);
  };
  

export const getUserName = ()=>{
    /* global Telegram*/
  const initData = parseQuery(Telegram.WebApp.initData);
  const unescapedData = unescapeJson(initData["user"]);
  const firstName = unescapedData.first_name;
  return firstName;
}