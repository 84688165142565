export const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const baseSettings = {

}

export const settings = isDevelopment ? {
    ...baseSettings,
    twaReturnUrl: 'https://t.me/emps_dev_bot',
    tonManifestUrl: "https://emps-vs.w-wings.ru/tonconnect-manifest-dev.json",
    backendUrl: "https://emps-vs.w-wings.ru/api",
} : {
    ...baseSettings,
    twaReturnUrl: 'https://t.me/emps_game_bot',
    tonManifestUrl: "https://emps.w-wings.ru/tonconnect-manifest.json",
    backendUrl: window.location.origin + "/api",
}