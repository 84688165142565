import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { settings } from "../../settings";
import { PrimaryButton } from "../../app/kit/buttons";
import { useDispatch, useSelector } from "react-redux";
import { goTo, routeNames } from "../../app/slices/navigationSlice";


const BattleStartPage = () => {
    const user = useSelector(x => x.user);
    const dispatch = useDispatch();
    const [countryList, setCountryList] = useState([]);
    const checkSlots = () => {
        axios.get(settings.backendUrl + "/battle/checkSlot").then(res => {
            console.log("Existing slot", res.data)
            if (res.data.existingSlot !== null) {
                dispatch(goTo({ route: routeNames.battlePrepare, args: { slot: res.data.existingSlot  } }));
            }else{
                setCountryList(res.data.countryNames);
            }
           
        }).finally(() => {
        });
    }

    const reduced = useMemo(() => {
        return countryList.length > 1 ? countryList.filter(x => x !== user.country) : []
    }
        , [countryList, user])

    useEffect(() => {
        checkSlots();
    }, []);

    
    const selectOpponentCountry = (x) => {
        dispatch(goTo({ route: routeNames.battlePrepare, args: { opponentCountry: x } }));
    }
    
    return (
        <div className="flex w-full justify-center">
            <div className="flex flex-col w-max">
                {reduced.map(x =>
                    <PrimaryButton className="w-full" text={x} onClick={() => { selectOpponentCountry(x) }} />
                )}
            </div>
        </div >
    )
}

export default BattleStartPage;