import { useDispatch, useSelector } from "react-redux";
import { NxpsIcon } from "../../icons/Nxps";
import { PrimaryButton, PrimaryRightButton } from "../../app/kit/buttons";
import { useEffect, useMemo } from "react";
import axios from "axios";
import { settings } from "../../settings";
import { investComplete, loadCountry, loadCountryRatings } from "../../app/slices/dataSlice";
import { Icon } from "@iconify/react/dist/iconify.js";
import { resourceToIcon } from "../Stock/StockPreview";
import { toUserFriendlyAddress } from "@tonconnect/ui-react";
import { AutoTextSize } from 'auto-text-size'
import { setModal } from "../../app/slices/modalSlice";
import { Modals } from "../Modals/ModalsCommon";
import { formNumber } from "../../app/helpers/numberFormatter";
import CountryPreview from "./CountryLanding";
const InvestorStat = ({ name, reward, perc }) => {
    return (
        <div className="flex flex-row justify-between bg-secondaryBg shadow shadow-black rounded-lg p-2 items-center bg-no-repeat bg-cover bg-center text-white" style={{backgroundImage:"url(leather.png)"}}>
            <p className="text-md ">{name}</p>
            <div className="inline-flex gap-1">
                <NxpsIcon className="w-6 h-6" />
                <p className="text-md font-medium">{formNumber(reward)}</p>
            </div>
            <p className="text-md font-medium">{formNumber(perc)}%</p>
        </div>
    )
}

const TopInvestorsBlock = ({ countryName }) => {

    const ratings = useSelector(x => x.data.countries[countryName]?.ratings?.playersRating);
    console.log(ratings);
    const reward = useSelector(state => state.data.countries[countryName].reward);
    const sorted = useMemo(() => { return ratings !== undefined && ratings.length > 0 ? ([...ratings].sort((a, b) => b?.investPerc - a?.investPerc)) : [] }, [ratings])
    return (
        <div className="flex flex-col gap-2 pt-10 px-4 pb-[6rem]">
            <p className="text-lg ">TOP INVESTORS</p>
            {sorted.map(x => (
                <InvestorStat
                    name={`${toUserFriendlyAddress(x.address, true).slice(0, 4)}...${toUserFriendlyAddress(x.address, true).slice(-4)}`}
                    reward={x.investPerc * reward / 100}
                    perc={x.investPerc}
                />
            ))}
        </div>
    )
}

const CountryResourceState = ({ countryName, resourceType, userResourceAmount }) => {

    const resource = useSelector(x => x.data.countries[countryName].resources[resourceType]);
    const requirement = useSelector(x => x.data.countries[countryName].resourceRequirements[resourceType]);

    const progress = useMemo(() => resource === null || resource === undefined ? 0 : resource.amount / requirement.amount * 100, [resource, requirement]);


    const dispatch = useDispatch();
    return (
        <div className="w-full px-2">
            <div className="inline-flex w-full s rounded-lg py-1 px-2">
                <div className="flex flex-col w-full gap-1">
                    <div className="w-full inline-flex items-center">
                        <div className="w-full">
                            <div aria-hidden="true" className="">
                                <div className="overflow-hidden rounded-full bg-white">
                                    <div style={{ width: `${progress}%` }} className="h-2 rounded-full bg-accent" />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center gap-1 justify-between">
                        <div className="flex flex-row items-center gap-1">
                            <Icon icon={resourceToIcon(resourceType).icon} className={resourceToIcon(resourceType).className + " w-6 h-6"} />
                            <p className="text-md font-medium">{formNumber(resource === null || resource === undefined ? 0 : resource.amount)} / {formNumber(requirement.amount)}</p>
                        </div>
                        {<p className="text-md font-medium">[{formNumber(userResourceAmount ?? 0)}]</p>}
                    </div>
                </div>
                <div className="w-max text-nowrap pl-2 text-md font-bold">
                    <PrimaryRightButton  disabled={userResourceAmount === undefined}
                    icon="game-icons:mine-wagon"
                        className="font-normal !py-2 !px-2 rounded-lg !shadow-md !shadow-slate-900"
                        onClick={() => dispatch(setModal({
                            id: Modals.InvestResourceModal, title: "Invest", resourceType, countryName
                        }))} />
                </div>
            </div>
        </div>
    )
}

const CountryResourceBlock = ({ countryName }) => {
    const requirements = useSelector(x => x.data.countries[countryName].resourceRequirements);
    const userResources = useSelector((state) => state.data.runtime.stock.resources);
    const userResourcesDict = useMemo(() => {
        const dict = {};
        userResources.forEach(resource => {
            dict[resource.resourceType] = resource.amount;
        });
        return dict;
    }, [userResources]);

    return (
        <div className="w-full flex flex-col gap-3 bg-no-repeat py-10 px-10 text-black/70" style={{backgroundImage:"url(papiero1.png)", backgroundSize:"100% 100%"}}>
            {requirements !== undefined && Object.keys(requirements).map(x => <CountryResourceState userResourceAmount={userResourcesDict[x]} countryName={countryName} resourceType={x} />)}
        </div>
    )
}


const RewardBlock = ({ countryName }) => {
    const reward = useSelector(state => state.data.countries[countryName].reward);
    const userPerc = useSelector(state => state.data.countries[countryName].ratings?.currentPlayerRating?.investPerc);
    return (
        <div className="flex flex-col gap-2 w-full self-center justify-center mt-4 py-4 px-10 rounded-lg w-full text-black/70 bg-no-repeat" 
        style={{backgroundImage:"url(papiero1.png)", backgroundSize:"100% 100%"}}>
            <div className="inline-flex gap-1 justify-between items-center">
                <p className="text-md">Total</p>
                <div className="inline-flex gap-1 items-center">                 
                    <p className="text-lg font-medium">{reward}</p>
                    <NxpsIcon className="w-8 h-8 ml-1" />
                </div>
            </div>
            {userPerc !== undefined && userPerc !== null &&
                <div className="inline-flex justify-between gap-1 items-center">
                    <p className="text-md">Your share <span className="font-medium">({formNumber(userPerc)}%)</span></p>
                    <div className="inline-flex gap-1 items-center justify-end">                        
                        <p className="text-lg font-medium">{formNumber(userPerc * reward / 100)}</p>
                        <NxpsIcon className="w-8 h-8 ml-1" />
                    </div>
                </div>
            }
        </div>
    )
}


const CountryTopBlock = ({ countryName, getRatings }) => {

    const dispatch = useDispatch();
    const level = useSelector(x => x.data.countries[countryName].currentLevel);
    const onInvestAll = () => {
        axios.post(settings.backendUrl + "/country/investAll?level=" + level).then(res => {
            if (res.status === 200) {
                dispatch(investComplete(res.data));
                //TODO: Thunk
                getRatings();
            }
        })
    }

    //TODO: Disable button if nothing to invest

    return (
        <div className="flex flex-col px-4 w-full gap-4 pt-5">
            <div className="flex flex-col w-full">
                <p className="text-lg ">REWARD</p>
                <RewardBlock countryName={countryName} />
            </div>
            <div className="inline-flex w-full justify-between pt-4 items-center">
                <p className="text-lg ">INVESTMENTS</p>
                <PrimaryRightButton text="INVEST ALL" icon="game-icons:mine-wagon" 
                onClick={onInvestAll} 
                className="!shadow-md !shadow-slate-900"/>
            </div>
        </div>
    )
}
const CountryHeader = () => {
    const user = useSelector(x => x.user);
    const dispatch = useDispatch();

    const getRatings = () => {
        axios.get(settings.backendUrl + "/country/ratings").then(res => {
            dispatch(loadCountryRatings(res.data));
        });
    }

    const getCountryInfo = () => {
        axios.get(settings.backendUrl + "/country/info").then(res => {
            dispatch(loadCountry(res.data));
            getRatings();
        })
    }

    useEffect(() => {
        getCountryInfo();
    }, [])

    return (
        <div className="w-full h-full max-h-96 inline-flex justify-center">
            <div className="flex flex-col w-full h-full relative">
                <CountryPreview/>
                <CountryTopBlock countryName={user.country} getRatings={getRatings} />
                <div className="w-full pt-3">
                    <CountryResourceBlock countryName={user.country} />
                    <TopInvestorsBlock countryName={user.country} />
                </div>
            </div>
        </div>
    )
}

export default CountryHeader;