import { useDispatch, useSelector } from "react-redux";
import BalanceView from "../Balances/BalanceView";
import CountryPreview from "../Country/CountryLanding"
import StockPreview from "../Stock/StockPreview";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Slider from "react-slick";
import { PrimaryButton } from "../../app/kit/buttons";
import { HeaderOutlet } from "../../app/kit/HeaderOutlet";
import { TonConnectButton } from "@tonconnect/ui-react";
import { changeSlide } from "../../app/slices/navigationSlice";
import { useEffect, useRef, useState } from "react";
import BarracksPreview from "../Barracks/BarracksPreview";
import { LandGrid, TrainingGrid } from "../Nfts/NftGrid";

const UserNameBlock = () => {

    const userName = useSelector(x => x.user.name);

    return (
        <div className="w-full flex flex-row justify-between items-center px-5 py-2">
            <p className="w-max text-md font-extrabold text-white ">
                {userName.toUpperCase()}
            </p>
            <div>
                <TonConnectButton />
            </div>
        </div>

    )
}

const MainPage = () => {

    const loaded = useSelector(x => x.data.isLoaded);
    const recordedIndex = useSelector(x => x.navigation.currentSlide);

    const dispatch = useDispatch();
    let sliderRef = useRef(null);
    var settings = {
        lazyLoad: "progressive",
        beforeChange: (current, next) => {
            dispatch(changeSlide(next));
        },
        dots: true,
        infinite: false,
        speed: 1000,
        initial: recordedIndex,
        touchThreshold: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true
    };

    useEffect(() => {
        sliderRef.slickGoTo(recordedIndex, false);
    }, [])

    return (
        <>
            {!loaded ? <div>Loading...</div> :
                <>
                    <HeaderOutlet alwaysVisible={true}>
                        <UserNameBlock />
                    </HeaderOutlet>
                    <CountryPreview showAttacks={true}/>
                    <BalanceView />
                    <Slider ref={slider => {
                        sliderRef = slider;
                    }}
                        {...settings}
                        className="w-full">
                        <div className="w-full">
                            <div className="woodenborder max-w-96 max-h-96 h-[22rem] w-[22rem] mx-auto">
                                <StockPreview />
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="woodenborder max-w-96 max-h-96 h-[22rem] w-[22rem] mx-auto">
                                <BarracksPreview />
                            </div>
                        </div>
                    </Slider>
                    <div className="py-10 mx-auto px-2 w-full">
                        {recordedIndex === 0 ? <div className="w-full py-2 inline-flex justify-between items-center px-2">
                            <p className=" text-lg">Your Lands</p>
                            <PrimaryButton className="py-1.5" size="small" text="Get more" onClick={() => {
                                /*global Telegram*/
                                Telegram.WebApp.openLink("https://testnet.getgems.io/collection/EQAbDCE7AqpooqNA5Hf4qqNOG-i9XPQN2X89emeRGTG_RkW6");
                            }} />
                        </div>
                    :    
                    <div className="w-full py-2 inline-flex justify-between items-center px-2">
                            <p className=" text-lg">Your Training Grounds</p>
                            <PrimaryButton className="py-1.5" size="small" text="Get more" onClick={() => {
                                /*global Telegram*/
                                Telegram.WebApp.openLink("https://testnet.getgems.io/collection/EQBLy1xZQKMW3--96yqSO8gKRAcaBVqoJhedrv3ZLu08as2s");
                            }} />
                        </div>
                    }
                        <div className="inline-flex w-full justify-center">
                            <div className="w-full max-w-96 pt-3">
                                {recordedIndex === 0? <LandGrid />:<TrainingGrid/>}
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default MainPage;


/*
<Carousel showArrows={true} emulateTouch>
                        <div>
                            <StockPreview />
                        </div>
                        <div>
                            <MarketPreview />
                        </div>
                    </Carousel>
*/