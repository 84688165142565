import { useMemo, useState } from "react"
import { ComboResourceInput } from "../../app/kit/comboInput"
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../../app/kit/buttons";
import axios from "axios";
import { settings } from "../../settings";
import { investComplete, loadCountryRatings } from "../../app/slices/dataSlice";




export const WinOrLooseModal = ({ onClose, data }) => {
   
   
    const win = data.data.win;
    return (
        <div className="w-full flex flex-col">
            <p>You {win?"Win!":"Lost!"}</p>
            <p>Stealed {data.data.stealed}</p>            
        </div>
    )
}