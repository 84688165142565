import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { settings } from '../settings';
import { setRefreshing } from './slices/userSlice';

// Action creator to request a new token
export const requestNewToken = createAsyncThunk(
    'auth/requestNewToken',
    async (id, { dispatch, getState }) => {
        
        if (getState().user.refreshingToken){
            return {status:null, data: null, skip:true};
        }
        dispatch(setRefreshing(true));
        const response = await axios.post(settings.backendUrl + '/refresh_token');
        return {status: response.status, data: response.data, skip:false};    
    }
);
