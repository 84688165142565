import { useSelector } from "react-redux";
import NftView from "./NftView";

export const LandGrid = () => {

    const nftMap = useSelector(x => x.data.lands);
    return (
        <div className="flex flex-col justify-center items-center w-full pb-[6rem]">
            {Object.keys(nftMap).map(x =>
                <div key={x} className="flex justify-center h-48 w-full">
                    <NftView nft={nftMap[x]} />
                </div>
            )}
        </div>
    )
}

export const TrainingGrid = () => {

    const nftMap = useSelector(x => x.data.trainings);
    return (
        <div className="flex flex-col justify-center items-center w-full pb-[6rem]">
            {Object.keys(nftMap).map(x =>
                <div key={x} className="flex justify-center h-48 w-full">
                    <NftView nft={nftMap[x]} />
                </div>
            )}
        </div>
    )
}