
import { useDispatch } from "react-redux";
import { PrimaryButton } from "../../app/kit/buttons";
import StockUpdate, { StockUpdateBalance } from "../Stock/StockUpdate";
import { goBack } from "../../app/slices/navigationSlice";



const StockUpdatePage = () => {
    return (
        <StockUpdate />
    )
}

export default StockUpdatePage;