import { Icon } from "@iconify/react/dist/iconify.js";
import { useDispatch, useSelector } from "react-redux";
import { NxpsIcon } from "../../icons/Nxps";
import { useEffect, useState } from "react";
import axios from "axios";
import { settings } from "../../settings";
import { upgradeLand } from "../../app/slices/dataSlice";
import Fireworks from "fireworks-js";
import { PrimaryButton } from "../../app/kit/buttons";
import { formNumber } from "../../app/helpers/numberFormatter";



const TrainingUpdateInfoBlock = ({ nextLevel, level }) => {
    const balance = useSelector(x => x.data.balance);
    return (
        <div className="w-max flex flex-col gap-4 relative bg-secondaryBg  rounded-lg px-4">
            <div className="flex flex-row gap-5 self-center pt-4 px-2">
                <div className="flex flex-col gap-3">
                    <p className="text-lg">LVL. {level.level}</p>
                    <p>Income. {level.income}</p>
                </div>
                <div className="inline-flex justify-center items-center">
                    <Icon icon="ion:arrow-undo-outline" className="h-16 w-16 rotate-[135deg] -mr-3" />
                </div>
                <div className="flex flex-col gap-3">
                    <p className="text-lg text-right">LVL. {nextLevel?.newLevel}</p>
                    <p className="text-right">Income. {nextLevel?.newIncome}</p>
                </div>
            </div>
            <div className="flex flex-row justify-center">
                <div className="inline-flex gap-1 items-center pb-2">
                    <NxpsIcon className="h-10 w-10" />
                    <p className="text-md">{level?.price} ({formNumber(balance.tokenAmount)})</p>
                </div>
            </div>
        </div>
    )
}

const TrainingUpdateMainBlock = ({ nftAddr }) => {
    const balance = useSelector(x => x.data.balance);
    const [fireworkActive, setFireworkActive] = useState(false);
    const [nextLevel, setNextLevel] = useState({});
    const nft = useSelector(x => x.data.lands[nftAddr]);

    const getNextLevelInfo = () => {
        axios.get(settings.backendUrl + "/training/levelInfo?level=" + (nft.level + 1) + "&landType=" + nft.landType).then((res) => {
            setNextLevel(res.data);
        })
    };

    useEffect(() => {
        getNextLevelInfo();
    }, [nft.level])


    const dispatch = useDispatch();

    const onUpgrade = () => {
        axios.post(settings.backendUrl + "/training/upgrade?nftAddress=" + nftAddr).then(res => {
            dispatch(upgradeLand(res.data));
            setFireworkActive(true);
        });
    }

    const [fireworks, setFireworks] = useState(null);

    useEffect(() => {
        const container = document.querySelector('.firework')
        setFireworks(new Fireworks(container, { /* options */ }));
    }, [])

    useEffect(() => {
        if (fireworkActive && fireworks) {
            fireworks.start();
            setTimeout(() => {
                setFireworkActive(false);
                fireworks.stop();
            }, 3000);
        }
    }, [fireworkActive, fireworks])

    return (
        <div className="flex flex-col w-full px-2">
            <div className="w-full relative inline-flex justify-center items-center pt-4">
                <TrainingUpdateInfoBlock level={{ level: nft.level, income: nft.hourlyIncome, price: nft.levelUpPrice }} nextLevel={nextLevel} />
                <div className="firework absolute w-full h-full top-0 left-0" />
            </div>
            <div className="inline-flex w-full justify-center pt-5">
                <PrimaryButton text="UPGRADE" 
                 className="!text-xl !py-3 !px-4 font-bold"
                onClick={onUpgrade} disabled={balance.tokenAmount < nft.levelUpPrice} />
            </div>
        </div>
    )
}

const TrainingUpgradeHeader = ({ nft }) => {
    return (
        <div className="w-full relative pt-6 pb-6">
            <p className="text-4xl w-full text-center">{nft.trainingType.toUpperCase()}</p>
        </div>
    )
}

const TrainingUpgradePage = () => {
    const nftAddr = useSelector(x => x.navigation.currentPage.args.nftAddr);
    const nft = useSelector(x => x.data.trainings[nftAddr]);
    return (
        <div className="flex flex-col w-full h-max relative bg-cover bg-no-repeat bg-center pb-5" style={{ backgroundImage: `url(${nft.nftImage})` }}>
            <TrainingUpgradeHeader nftAddr={nftAddr} nft={nft} />
            <TrainingUpdateMainBlock nftAddr={nftAddr} />
        </div>
    )
}

export default TrainingUpgradePage;