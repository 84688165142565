import { Icon } from "@iconify/react/dist/iconify.js"
import { Link } from "react-router-dom"
import { tryVibrate } from "../helpers/vibrateHelper"


export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const PrimaryButton = ({ text, onClick, className, icon, size, iconClassName, disabled = false }) => {

  const onVibrateClick = () => {
    tryVibrate();
    onClick && onClick();
  }

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onVibrateClick}
      className={classNames("relative w-max shadow-sm shadow-gray-900 text-white inline-flex items-center gap-x-2 bg-btnclr px-3.5 py-0.5 text-lg hover:bg-white",
        className, size === "small" ? "text-sm px-1.5 py-0.5" : "text-lg px-3.5 py-0.5", disabled ? "pointer-events-none !bg-gray-400 !text-gray-300" : "")}
    >
      {text !== undefined && text !== "" && <span className="">{text}</span>}
      {icon !== null && icon !== undefined && <Icon icon={icon} className={classNames("-mr-0.5 h-5 w-5", iconClassName)} />}
    </button>
  )
}

export const PrimaryRightButton = ({ text, onClick, className, icon, size, iconClassName, disabled = false }) => {

  const onVibrateClick = () => {
    tryVibrate();
    onClick && onClick();
  }

  return (
    <span className="filterShadow-sm ">
      <button
        type="button"
        disabled={disabled}
        onClick={onVibrateClick}
        className={classNames("bg-cover bg-no-repeat -mr-2.5 rightButtonMark relative w-max text-black/50 inline-flex items-center gap-x-2 bg-btnclr px-3.5 py-0.5 text-lg hover:bg-white",
          className, size === "small" ? "text-sm px-1.5 py-0.5" : "text-lg px-3.5 py-0.5", disabled ? "pointer-events-none !bg-gray-400 !text-black/20" : "")}
        style={{ backgroundImage: "url(leather.png)" }}
      >
        {text !== undefined && text !== "" && <div className="pl-2 inline-flex items-center"><span>{text}</span></div>}
        {icon !== null && icon !== undefined && <Icon icon={icon} className={classNames("-mr-0.5 h-5 w-5", iconClassName)} />}
      </button>
    </span>
  )
}


export const PrimaryEmblemRightButton = ({ text, rightText, Emblem, onClick, className, size, disabled = false }) => {

  const onVibrateClick = () => {
    tryVibrate();
    onClick && onClick();
  }

  return (
    <span className="filterShadow-sm">
      <div className="rightButtonMark flex items-center -mr-2.5">
        <button
          type="button"
          disabled={disabled}
          onClick={onVibrateClick}
          className={classNames("bg-cover my-2 bg-no-repeat w-max text-black/50 inline-flex items-center gap-x-2 bg-btnclr px-3.5 py-0.5 text-lg hover:bg-white",
            className, size === "small" ? "text-sm px-1.5 py-0.5" : "text-lg px-3.5 py-0.5", disabled ? "pointer-events-none !bg-gray-400 !text-gray-300" : "")}
          style={{ backgroundImage: "url(leather.png)" }}
        >
          {text !== undefined && text !== "" && <span className="pl-2 -mb-0.5">{text}</span>}
          {Emblem !== null && Emblem !== undefined &&
            <div className="h-5 w-10 relative">
              <Emblem className="flex absolute z-50 h-10 w-10 -top-1/2 left-0" />
            </div>}
          {rightText !== undefined && rightText !== "" && <span className="pl-2 -mb-0.5">{rightText}</span>}
        </button>
      </div>
    </span>
  )
}
