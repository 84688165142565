import { useEffect, useMemo, useRef, useState } from "react";
import { useDimensions } from "../../app/helpers/useDimensions";
import { Tooltip } from "react-tooltip";
import axios from "axios";
import { settings } from "../../settings";
import { ComboForceInput } from "../../app/kit/comboInput";
import { useDispatch, useSelector } from "react-redux";
import { classNames, PrimaryButton } from "../../app/kit/buttons";
import { Icon } from "@iconify/react/dist/iconify.js";
import { forceToIcon } from "../Barracks/BarracksPreview";
import { ReactSortable } from "react-sortablejs";
import { goBack } from "../../app/slices/navigationSlice";

const landToIcon = (land) => {
    switch (land) {
        case "Mountain": return <Icon icon="la:mountain" className="w-6 h-6" />;
        case "Forest": return <Icon icon="material-symbols:forest" className="w-6 h-6" />;
        case "Desert": return <Icon icon="hugeicons:desert" className="w-6 h-6" />;
        case "Fields": return <Icon icon="hugeicons:soil-temperature-field" className="w-6 h-6" />;
    }
}

export const SubmitNewForceBlock = ({ lastIndex, armies, forces, forceTypes, onSubmit, onCancel }) => {

    const [selectedForces, setSelectedForces] = useState({
        forceType: forceTypes[0],
        amount: 0,
        order: lastIndex + 1
    });

    const onSetAmount = (v) => {
        let rounded = Math.floor(v);
        setSelectedForces(selectedF => {
            //Check user available forces: subtract army forces of the same type from forces
            const armyForces = armies.filter(x => x.forceType === selectedF.forceType);
            const armyAmount = armyForces.length > 0 ? armyForces[0].amount : 0;
            const userForces = forces.filter(x => x.forceType === selectedF.forceType);
            const userAmount = userForces.length > 0 ? userForces[0].amount : 0;
            const available = Math.floor(userAmount - armyAmount);
            let toSubmit = rounded > available ? available : rounded;
            return { ...selectedF, amount: toSubmit }
        })
    }

    const onMaxClick = () => {
        setSelectedForces(selectedF => {
            //Check user available forces: subtract army forces of the same type from forces
            const armyForces = armies.filter(x => x.forceType === selectedF.forceType);
            const armyAmount = armyForces.length > 0 ? armyForces[0].amount : 0;
            const userForces = forces.filter(x => x.forceType === selectedF.forceType);
            const userAmount = userForces.length > 0 ? userForces[0].amount : 0;
            const available = Math.floor(userAmount - armyAmount);
            return { ...selectedF, amount: available }
        })
    }

    const onForceTypeSelected = (newForceType) => {
        console.log("Setting new force type", newForceType);
        setSelectedForces(selectedF => {
            let currentSelectedAmount = selectedF.amount;
            //Check user available forces: subtract army forces of the same type from forces
            const armyForces = armies.filter(x => x.forceType === newForceType);
            const armyAmount = armyForces.length > 0 ? armyForces[0].amount : 0;
            const userForces = forces.filter(x => x.forceType === newForceType);
            const userAmount = userForces.length > 0 ? userForces[0].amount : 0;
            const available = Math.floor(userAmount - armyAmount);
            let toSubmit = currentSelectedAmount > available ? available : currentSelectedAmount;
            return { ...selectedF, forceType: newForceType, amount: toSubmit }
        })
    }



    return (
        <div className="w-full px-2">
            <ComboForceInput
                amount={selectedForces.amount}
                setAmount={onSetAmount}
                setForce={onForceTypeSelected}
                force={selectedForces.forceType}
                options={forceTypes}
                maxAmountClick={onMaxClick}
            />
            {selectedForces.amount > 0 && <PrimaryButton text="Add" onClick={() => { onSubmit(selectedForces) }} />}
            <PrimaryButton text="Cancel" onClick={onCancel} />
        </div>
    )
}

const BattlePreparePage = () => {


    const [editSlot, setEditSlot] = useState(-1);
    const opponentCountry = useSelector(x => x.navigation.currentPage.args.opponentCountry);
    const externalSlot = useSelector(x => x.navigation.currentPage.args.slot)
    const [battleSlot, setBattleSlot] = useState(externalSlot ?? null);
    const [selectedForces, setSelectedForces] = useState([]);

    const userForces = useSelector((state) => state.data.runtime.barracks.forces);
    const userForceTypes = useMemo(() => userForces.map(x => x.forceType), [userForces]);

    const requestBattleSlot = () => {
        axios.get(settings.backendUrl + "/battle/createSlot?country=" + opponentCountry).then(res => {
            setBattleSlot(res.data);
        });
    }
    const dispatch = useDispatch();
    const fillBattleSlot = () => {
        axios.post(settings.backendUrl + "/battle/fillSlots", {
            slotId: battleSlot.battleSlotId,
            armies: selectedForces
        }).then(res => {
            dispatch(goBack());
        })
    }

    useEffect(() => {
        console.log("External slot", externalSlot)
        if (externalSlot === undefined) {
            requestBattleSlot();
        }
    }, [])

    const onSetAfterSwap = (newState) => {
        setSelectedForces([...newState].map((x, i) => ({ ...x, order: i + 1 })));
    }

    const onSubmitForce = (force) => {
        var index = selectedForces.findIndex(x => x.order === force.order);
        if (index !== -1) {
            setSelectedForces([...selectedForces.slice(0, index), force, ...selectedForces.slice(index + 1)]);
        }
        else {
            setSelectedForces([...selectedForces, force]);
        }
        setEditSlot(-1);
    }

    return (
        <div className="w-full h-full flex flex-col relative">
            <div className="w-full inline-flex pb-5 justify-center">
                <div className="flex flex-col">
                    <div>Forest: {battleSlot?.battleConfig["Forest"]}%</div>
                    <div>Mountains: {battleSlot?.battleConfig["Mountains"]}%</div>
                    <div>Hills: {battleSlot?.battleConfig["Hills"]}%</div>
                    <div>Fields: {battleSlot?.battleConfig["Fields"]}%</div>
                </div>
            </div>
            <div className="w-full inline-flex pb-5 justify-center">
                <p>-------{battleSlot?.countryName}-------</p>
            </div>
            <div className="w-full h-full flex flex-col">
                <div className="w-full inline-flex justify-center pb-10">
                    <ReactSortable list={selectedForces} setList={onSetAfterSwap} className="flex flex-col gap-2 text-black/70">
                        {selectedForces.map((force) => (
                            <div key={force.order} className="bg-no-repeat inline-flex px-2 py-1" style={{ backgroundImage: "url(papiero1.png)", backgroundSize: "100% 100%" }}>
                                <p className="mr-2">{force.order}.</p>
                                <Icon icon={forceToIcon(force.forceType).text} className="w-6 h-6" />
                                <p className="">{force.amount}</p>
                                <div className="w-2 ml-3 mr-2">|||</div>
                            </div>
                        ))}
                    </ReactSortable>
                </div>
                <div className="w-full pb-[8rem] flex flex-col">
                    {editSlot >= 0 &&
                        <SubmitNewForceBlock
                            lastIndex={selectedForces.length}
                            armies={selectedForces}
                            forces={userForces}
                            forceTypes={userForceTypes}
                            onCancel={() => { setEditSlot(-1) }}
                            onSubmit={onSubmitForce} />
                    }
                    {editSlot < 0 && <PrimaryButton text="Add" onClick={() => { setEditSlot(0) }} />}
                </div>
                {selectedForces.length > 0 &&
                    <div className="w-full inline-flex pb-5 justify-center">
                        <PrimaryButton text={"Done"} onClick={fillBattleSlot} />
                    </div>
                }
            </div>
        </div>
    )
}

export default BattlePreparePage;