import { classNames } from "../app/kit/buttons";
import grainIcon from "./Resources/grain.png"
import woodIcon from "./Resources/wood.png"
import stoneIcon from "./Resources/stone.png"
import bricksIcon from "./Resources/bricks.png"

export const GrainIcon = ({className})=>{
    return(
        <img src={grainIcon} className={className}/>
    )
}

export const WoodIcon = ({className})=>{
    return(
        <img src={woodIcon} className={className}/>
    )
}

export const StoneIcon = ({className})=>{
    return(
        <img src={stoneIcon} className={className}/>
    )
}

export const BricksIcon = ({className})=>{
    return(
        <img src={bricksIcon} className={className}/>
    )
}