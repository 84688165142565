import {useEffect, useRef} from "react";
import {toUserFriendlyAddress, useIsConnectionRestored, useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { rejectUser, setToken } from "../app/slices/userSlice";

import { getUserName } from "./nameHelper";
import { settings } from "../settings";

const payloadTTLMS = 1000 * 360;

const generateProof = async ()=>{
    const payload = (await axios.get(settings.backendUrl+"/gen-proof")).data.payload;
    return { tonProof: payload };
}

const checkProof = async (proof, account)=>{
    const reqBody = {
        address: account.address,
        friendly_address: toUserFriendlyAddress(account.address, account.chain === "-3"),
        network_type: account.chain === "-3" ? "Testnet": "Mainnet",
        proof: {
          ...proof,
          state_init: account.walletStateInit,
          public_key: account.publicKey,
        },
        userName: getUserName()
      };
      
      let proofResult = await axios.post(settings.backendUrl+"/verify-proof", reqBody);
      return proofResult;
}

export function useBackendAuth() {
    const isConnectionRestored = useIsConnectionRestored();
    const wallet = useTonWallet();
    const [tonConnectUI] = useTonConnectUI();
    const interval = useRef();

    const userLoggedIn = useSelector(x=>x.user);
    const dispatch = useDispatch();

    useEffect(()=>{
        const handleDisconnection = () => {
            console.log("Auth: Handling disconnection");   
            dispatch(rejectUser());
        };

        window.addEventListener('ton-connect-ui-disconnection', handleDisconnection);

        return () => {
            window.removeEventListener('ton-connect-ui-disconnection', handleDisconnection);
        };
    },[]);
    
    useEffect(() => {
        if (!isConnectionRestored) {
            console.log("Auth: TON Connection restored");
            return;
        }

        clearInterval(interval.current);
        if (!wallet) {    
            console.log("Auth: TON Wallet disconnected");    
            dispatch(rejectUser());

            const refreshPayload = async () => {                
                tonConnectUI.setConnectRequestParameters({ state: 'loading' });

                const value = await generateProof();
                if (!value) {
                    tonConnectUI.setConnectRequestParameters(null);
                } else {
                    tonConnectUI.setConnectRequestParameters({state: 'ready', value});
                }
            }

            refreshPayload();
            setInterval(refreshPayload, payloadTTLMS);
            return;
        }

        if (userLoggedIn.loggedIn) {
            console.log("Auth: already logged in");    
            return;
        }

        if (wallet.connectItems?.tonProof && !('error' in wallet.connectItems.tonProof)) {
            console.log("Auth: TON check proof");    
            checkProof(wallet.connectItems.tonProof.proof, wallet.account).then(result => {
                if (result) {
                    //received cookie here, set user
                    dispatch(setToken(result.data));
                } else {
                    tonConnectUI.disconnect();
                }
            })
        } else {
            console.log("Auth: Disconnecting due to no proof");    
            tonConnectUI.disconnect();
        }

    }, [wallet, isConnectionRestored])
}   
